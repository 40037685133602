import React, { useEffect, useState } from 'react';

import styles from '@sicredi/styles/_notification-badge.scss';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  icon: React.ReactNode;
  info?: number | string;
  show: boolean;
}

const NotificationBadge: React.FC<Props> = ({
  icon,
  info,
  show,
  ...props
}) => {
  const [infoBadge, setInfoBadge] = useState<string>(
    info ? info.toString() : ''
  );

  useEffect(() => {
    if (typeof info === 'number' && info > 99) {
      setInfoBadge('99+');
    }
  }, [info]);

  return (
    <div
      aria-label={props['aria-label']}
      className={styles['sicredi-notification-badge']}
      data-testid={props['data-testid']}
      {...props}
    >
      {show && (
        <div
          aria-hidden="true"
          className={styles['notification']}
          data-testid={
            props['data-testid'] && `${props['data-testid']}-notification`
          }
        >
          <div
            aria-hidden="true"
            className={styles['badge']}
            data-testid={
              props['data-testid'] && `${props['data-testid']}-badge`
            }
          >
            {infoBadge}
          </div>
        </div>
      )}
      <div
        aria-hidden="true"
        className={styles['icon']}
        data-testid={props['data-testid'] && `${props['data-testid']}-icon`}
      >
        {icon}
      </div>
    </div>
  );
};

export default NotificationBadge;
