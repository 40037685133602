import { createContext, ReactNode, useContext, useState } from 'react';

interface ModalContextType {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialValue: ModalContextType = {
  isModalVisible: false,
  setIsModalVisible: () => {},
};

export const ModalContext = createContext<ModalContextType>(initialValue);

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <ModalContext.Provider value={{ isModalVisible, setIsModalVisible }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const { isModalVisible, setIsModalVisible } = useContext(ModalContext);

  return {
    isModalVisible,
    setIsModalVisible,
  };
};
