import React from 'react';
import styles from '@sicredi/styles/_carousel.scss';
import cn from 'clsx';

import Item, { Props as ItemProps } from './Item';
import Button from '../button/Button';
import { useId } from '../utils/useId';
import { Icon } from '../icon';

interface BaseProps extends React.HTMLAttributes<HTMLElement> {
  caption: (currentItem: number, total: number) => React.ReactNode;
  previousButtonLabel?: string;
  nextButtonLabel?: string;
  children?: React.ReactNode;
  initial?: number;
  size?: 'small' | 'medium' | 'large';
}

type AriaLabelProps = { 'aria-label': string } | { 'aria-labelledby': string };

export type Props = BaseProps & AriaLabelProps;

export interface CompoundedCarousel extends React.FC<Props> {
  Item: React.FC<ItemProps>;
}

export const Carousel: CompoundedCarousel = ({
  previousButtonLabel,
  nextButtonLabel,
  children,
  caption,
  size,
  ...props
}) => {
  const [active, setActive] = React.useState(0);
  const items = React.Children.toArray(children);
  const controlsId = useId('items');
  const captionId = useId('caption');

  function handleClickPrevious() {
    setActive(prev => (prev > 0 ? prev - 1 : items.length - 1));
  }

  function handleClickNext() {
    setActive(prev => (prev < items.length - 1 ? prev + 1 : 0));
  }

  return (
    <div
      className={cn({
        [styles['sicredi-carousel']]: true,
        [styles['-small']]: size === 'small',
        [styles['-medium']]: size === 'medium',
        [styles['-large']]: size === 'large',
      })}
      role="region"
      aria-roledescription="carousel"
      {...props}
      data-testid={
        props['data-testid'] ? `${props['data-testid']}-wrapper` : undefined
      }
    >
      <ul
        id={controlsId}
        className={styles['items']}
        aria-live="polite"
        data-testid={
          props['data-testid']
            ? `${props['data-testid']}-items-container`
            : undefined
        }
      >
        {items.map((item: React.ReactElement<ItemProps>, index) =>
          React.cloneElement(item, {
            active: index === active,
            ariaLabelledby: captionId,
          })
        )}
      </ul>
      <div
        className={styles['controls']}
        data-testid={
          props['data-testid']
            ? `${props['data-testid']}-controls-container`
            : undefined
        }
      >
        <Button
          aria-controls={controlsId}
          onClick={handleClickPrevious}
          className={styles['previous']}
          data-testid={
            props['data-testid']
              ? `${props['data-testid']}-button-previous`
              : undefined
          }
        >
          <Button.Icon>
            <Icon name="chevron-left" />
          </Button.Icon>
          {previousButtonLabel}
        </Button>
        <span
          className={styles['caption']}
          id={captionId}
          data-testid={
            props['data-testid'] ? `${props['data-testid']}-caption` : undefined
          }
        >
          {caption(active + 1, items.length)}
        </span>
        <Button
          aria-controls={controlsId}
          onClick={handleClickNext}
          className={styles['next']}
          data-testid={
            props['data-testid']
              ? `${props['data-testid']}-button-next`
              : undefined
          }
        >
          {nextButtonLabel}
          <Button.Icon>
            <Icon name="chevron-right" />
          </Button.Icon>
        </Button>
      </div>
    </div>
  );
};

Carousel.defaultProps = {
  previousButtonLabel: 'Anterior',
  nextButtonLabel: 'Próximo',
  size: 'medium',
};

Carousel.Item = Item;
