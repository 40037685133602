import React from 'react';
import styles from '@sicredi/styles/_file.scss';
import { Button } from '../button';
import { Props as SicrediButtonProps } from '../button/Button';
import { Icon } from '../icon';

export interface File {
  id?: string;
  name: string;
  type?: string;
}

export interface Props
  extends Pick<
  SicrediButtonProps,
  Exclude<keyof SicrediButtonProps, 'onClick'>
  > {
  onRemove?: (index: number) => void;
  onClick?: (file: File) => void;
  file: File;
}

const FILENAME_SLICE_INDEX: number = -9;

const Item: React.FC<Props> = ({
  onRemove,
  onClick,
  index,
  file,
  className,
  ...props
}) => {

  return (
    <li data-item="" className={styles['item']}>
      <Button
        className={styles['name']}
        appearance="link"
        onClick={() => onClick && onClick(file)}
        {...props}
        data-testid={
          props['data-testid'] ? `${props['data-testid']}-item-name` : undefined
        }
      >
        <span className={styles['left']}>
          {file.name.slice(0, FILENAME_SLICE_INDEX)}
        </span>
        <span className={styles['right']}>
          {file.name.slice(FILENAME_SLICE_INDEX)}
        </span>
      </Button>
      {onRemove && (
        <button
          type="button"
          onClick={() => onRemove(index)}
          tabIndex={0}
          className={styles['destroy']}
          aria-label={`Remover ${file.name}`}
          data-button=""
          data-testid={
            props['data-testid']
              ? `${props['data-testid']}-item-destroy`
              : undefined
          }
        >
          <Icon className={styles['icon']} name="close" />
        </button>
      )}
    </li>
  );
};

export default Item;
