import axios from 'axios';
import { APPLICATION } from '../../constants';
import { UserConsentAblePT } from '../Interfaces/UserConsent.Interface';

export async function getConsent(userId: string, domain_: string) {
  const { data } = await axios.get<UserConsentAblePT>(
    `${APPLICATION.BFF_URL}consentimentos?usuario=${userId}&dominio=${domain_}`,
  );

  return data;
}
