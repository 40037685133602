import React from 'react';
import variables from '@sicredi/styles/_variables.scss';
import styles from '@sicredi/styles/_checkbox.scss';
import { Icon } from '../icon';

interface CheckboxIconProps {
  checked?: boolean;
  style?: { [key: string]: any };
}
const CheckboxIcon = ({
  checked,
  style = {},
}: CheckboxIconProps) => {
  const commonStyles = {
    transition: `opacity ${variables.animationTiming}`,
    right: 0,
    ...style,
  };
  return (
    <span className={styles['icon']} style={{ position: 'relative' }}>
      <Icon
        name="checkbox-filled"
        style={{
          ...commonStyles,
          opacity: Number(checked),
          position: 'absolute'
        }}
      />
      <Icon
        name="checkbox"
        style={{
          ...commonStyles,
          opacity: Number(!checked),
          position: 'absolute'
        }}
      />
    </span>
  );
};

export default CheckboxIcon;
