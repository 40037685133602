import * as React from 'react';
import { mask } from '@sicredi/mask';

import { Props as InputProps } from '../input/Input';
import MaskedInput, {
  Props as MaskedInputProps,
} from '../masked-input/MaskedInput';

export interface Props
  extends Pick<InputProps, Exclude<keyof InputProps, 'onChange'>> {
  children?: React.ReactNode;
  onChange?: (value: string) => void;
  config?: MaskedInputProps['config'];
}

export interface CompoundedCurrentInput extends React.FC<Props> {
  parse: (percentage: string) => Number;
  format: (
    percentage: number | string,
    config?: MaskedInputProps['config']
  ) => string;
}

const PercentageInput: CompoundedCurrentInput = ({
  value,
  onChange,
  onBlur,
  config,
  ...props
}) => {
  const [internalValue, setInternalValue] = React.useState('');

  const inputRef = React.createRef<HTMLInputElement>();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInternalValue(e.target.value);
    onChange && onChange(e.target.value);
  }

  function handleOnKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (!!config && "minDecimalLimit" in config) {
      const elementTarget = e.target as HTMLInputElement;
      const cursorPosition = Number(elementTarget.selectionStart);
      const keepCursorSamePosition = () => elementTarget.setSelectionRange(cursorPosition, cursorPosition);
      const BACKSPACE_KEYCODE = 'Backspace';

      if (e.key === BACKSPACE_KEYCODE) {
        e.preventDefault();
        e.stopPropagation();
  
        if (window.getSelection()!.toString()) {
          elementTarget.value = elementTarget.value.replace(window.getSelection()!.toString(), '');
          return keepCursorSamePosition();
        }
        
        const valueList = elementTarget.value.split('');
        valueList.splice(cursorPosition-1, 1);
        elementTarget.value = valueList.join('');
        keepCursorSamePosition();
      }
    }
  }

  function applyMask(e: React.FocusEvent<HTMLInputElement>) {
    if (!!config && "minDecimalLimit" in config) {
      const cleanValue = e.target.value.replace('%', '').replace(',', '.');
      const percent = Number(cleanValue).toLocaleString('pt-BR', {minimumFractionDigits: config.minDecimalLimit });
      e.target.value = PercentageInput.format(percent, {...config});
      handleChange(e);
      onBlur && onBlur(e);
    }
  }

  return (
    <MaskedInput
      ref={inputRef}
      mask="PERCENTAGE"
      value={value !== undefined ? value : internalValue}
      onChange={handleChange}
      preserveChars={[',']}
      config={config}
      onBlur={applyMask}
      onKeyDown={handleOnKeyDown}
      {...props}
    />
  );
};

PercentageInput.parse = function(percentage: string): number {
  return parseFloat(percentage.replace(',', '.').replace(/[^\d.-]/g, ''));
};

PercentageInput.format = function(
  percentage: number | string,
  config?: MaskedInputProps['config']
): string {
  return mask(percentage, 'PERCENTAGE', config);
};

export default PercentageInput;