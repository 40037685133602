import * as React from 'react';
import cn from 'clsx';
import dlv from 'dlv';

import { hash } from '../utils';
import { Ellipsis } from '../shared/ellipsis';
import useExpanded from './hooks/useExpanded';
import { DropdownMenu } from '../dropdown-menu';
import useSortBy, { Order, SortBy } from './hooks/useSortBy';
import { Props as DropdownMenuItemProps } from '../dropdown-menu/Item';

import styles from '@sicredi/styles/_table.scss';
import Button, { Props as SicrediButtonProps } from '../button/Button';
import { Icon } from '../icon';

type Accessor = (
  data: { [key: string]: any },
  index?: number
) => React.ReactNode;

export interface SortableColumn {
  sortable?: boolean;
  onSort?: (order: Order) => void;
  disableSorting?: boolean;
  sortAriaLabel?: string;
  initiallySorted?: 'ascending' | 'descending';
}

export type Column = {
  label: React.ReactNode;
  width?: string;
  fixedLeft?: boolean;
  fixedRight?: boolean;
  align?: 'left' | 'center' | 'right';
  accessor: string | Accessor;
} & SortableColumn;

export type Row = {
  expandable?: boolean;
  [key: string]: any;
};

export type FunctionAction = (row: Row) => Partial<DropdownMenuItemProps>;
export type Action = Partial<DropdownMenuItemProps> | FunctionAction;

export interface Props extends React.HTMLAttributes<HTMLTableElement> {
  children?: React.ReactNode;
  rows: Row[];
  columns: Column[];
  actions?: Action[];
  expandable?: (row: object) => React.ReactNode | boolean;
  anchor?: (row: Row) => SicrediButtonProps;
  noRowsMessage?: React.ReactNode;
  height?: number | string;
  resetExpandOnChanges?: boolean;
  linesPerCell?: number;
}

function getInitialSorting(columns: SortableColumn[]): SortBy {
  const column = columns.findIndex(c => c.initiallySorted);
  const order = dlv(columns[column], 'initiallySorted', 'descending');
  return { column, order };
}

const Table: React.FC<Props> = ({
  rows,
  columns,
  className,
  expandable,
  noRowsMessage,
  actions,
  anchor,
  height,
  resetExpandOnChanges,
  linesPerCell,
  ...props
}) => {
  const [sortBy, setSortBy] = useSortBy(getInitialSorting(columns));
  const [expandedRows, setExpandedRows, resetExpandedRows] = useExpanded();
  const [dropdownMenuVisible, setDropdownMenuVisible] = React.useState<
    number
  >();
  const tableRef = React.useRef<HTMLDivElement>(null);
  const isSorted = (columnIndex: number) => sortBy.column === columnIndex;
  const isRowExpanded = (rowIndex: number) => expandedRows[rowIndex];
  const isRowExpandable = (row: Row) => row.expandable !== false;

  React.useEffect(() => {
    if (!resetExpandOnChanges) return;

    resetExpandedRows();
  }, [rows]);

  React.useEffect(() => {
    function onResize() {
      if (!tableRef.current) return;
      const hasHorizontalScroll =
        tableRef.current.scrollWidth > tableRef.current.clientWidth;
      tableRef.current.classList[hasHorizontalScroll ? 'add' : 'remove'](
        styles['-horizontal-scroll']
      );
    }
    onResize();

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [tableRef.current]);

  function onScroll() {
    if (!tableRef.current) return;

    const hasScrolledToTop = tableRef.current.scrollTop === 0;
    const ths = tableRef.current.querySelectorAll('th');
    ths.forEach(th =>
      th.classList[!hasScrolledToTop ? 'add' : 'remove'](styles['-sticky'])
    );
  }

  const renderSortIcon = ({ columnIndex }: { columnIndex: number }) => {
    if (isSorted(columnIndex)) {
      return(
        <>
          {sortBy.order === 'ascending' && <Icon name='order-up' size={16} className={styles['icon']} />}
          {sortBy.order === 'none' && <Icon name='order-up' size={16} className={styles['icon']} />}
          {sortBy.order === 'descending' && <Icon name='order-down' size={16} className={styles['icon']} />}        
        </>
      )
    }

    return <Icon name='order-no-position' size={16} className={styles['icon']} />
  }


  return (
    <div
      ref={tableRef}
      className={cn(styles['sicredi-table-scroller'])}
      style={{ maxHeight: height }}
      onScroll={onScroll}
    >
      <table className={cn(styles['sicredi-table'], className)} {...props}>
        <thead data-head="">
          <tr
            data-row=""
            data-testid={
              props['data-testid'] ? `${props['data-testid']}-tr` : undefined
            }
          >
            {columns.map((column, i) => (
              <th
                key={hash(i)}
                style={{ width: column.width }}
                className={cn({
                  [styles['-fixed-left']]: column.fixedLeft,
                  [styles['-fixed-right']]: column.fixedRight,
                  [styles['-left']]: column.align === 'left',
                  [styles['-center']]: column.align === 'center',
                  [styles['-right']]: column.align === 'right',
                })}
                data-column=""
                aria-sort={isSorted(i) ? sortBy.order : undefined}
                data-testid={
                  props['data-testid']
                    ? `${props['data-testid']}-th`
                    : undefined
                }
              >
                {!column.sortable ? (
                  column.label
                ) : (
                  <button
                    type="button"
                    disabled={column.disableSorting}
                    onClick={() => {
                      if (!column.onSort) return;

                      const order = setSortBy(i);
                      column.onSort(order);
                    }}
                    aria-label={column.sortAriaLabel}
                    data-testid={
                      props['data-testid']
                        ? `${props['data-testid']}-sort-button`
                        : undefined
                    }
                  >
                    {column.label}
                    
                    {renderSortIcon({ columnIndex: i })}
                  </button>
                )}
              </th>
            ))}

            {actions || expandable || anchor ? (
              <th
                className={cn({
                  [styles['-fixed-right']]: true,
                  [styles['-actions']]: actions,
                  [styles['-expandable']]: expandable,
                  [styles['-anchor']]: anchor,
                })}
                data-testid={
                  props['data-testid']
                    ? `${props['data-testid']}-th-actions`
                    : undefined
                }
              />
            ) : null}
          </tr>
        </thead>
        <tbody data-body="">
          {rows.length ? (
            rows.map((row, i) => {
              const newActions = (actions || [])
                .map((action, index) => {
                  const props =
                    typeof action === 'function' ? action(row) : action;

                  if (!props) return;

                  return (
                    <DropdownMenu.Item key={hash(`${i}${index}`)} {...props} />
                  );
                })
                .filter(action => !!action);

              return (
                <React.Fragment key={hash(i)}>
                  <tr
                    data-row=""
                    className={cn({ [styles['-odd']]: (i + 1) % 2 !== 0 })}
                    data-testid={
                      props['data-testid']
                        ? `${props['data-testid']}-tr`
                        : undefined
                    }
                  >
                    {columns.map((column, j) => (
                      <td
                        key={hash(`${i}${j}`)}
                        data-column=""
                        className={cn({
                          [styles['-fixed-left']]: column.fixedLeft,
                          [styles['-fixed-right']]:
                            column.fixedRight && !newActions.length,
                          [styles['-left']]: column.align === 'left',
                          [styles['-center']]: column.align === 'center',
                          [styles['-right']]: column.align === 'right',
                        })}
                        data-testid={
                          props['data-testid']
                            ? `${props['data-testid']}-td`
                            : undefined
                        }
                      >
                        <Ellipsis lineClamp={linesPerCell}>
                          {typeof column.accessor === 'function'
                            ? column.accessor(row, i)
                            : dlv(row, column.accessor)}
                        </Ellipsis>
                      </td>
                    ))}

                    {newActions.length || expandable || anchor ? (
                      <td
                        className={cn(
                          styles['-right'],
                          styles['-fixed-right'],
                          styles['-actions']
                        )}
                        style={{ zIndex: dropdownMenuVisible === i ? 2 : 1 }}
                        data-testid={
                          props['data-testid']
                            ? `${props['data-testid']}-actions-td`
                            : undefined
                        }
                      >
                        {newActions.length ? (
                          <>
                            {dropdownMenuVisible === i && <div />}
                            <DropdownMenu
                              aria-label="Ações"
                              data-dropdown-menu=""
                              onChange={({ visible }) =>
                                setDropdownMenuVisible(visible ? i : undefined)
                              }
                              triggerProps={{
                                children: (
                                  <Icon name="actions" appearance="primary" />
                                ),
                              }}
                              portal={true}
                            >
                              {newActions}
                            </DropdownMenu>
                          </>
                        ) : null}

                        {expandable && isRowExpandable(row) && (
                          <button
                            type="button"
                            aria-label="Expandir linha"
                            aria-controls={hash(`expanded-${i}`)}
                            aria-expanded={isRowExpanded(i) ? 'true' : 'false'}
                            onClick={() => setExpandedRows(i)}
                          >
                            <Icon
                              name="chevron-down"
                              className={styles['icon']}
                            />
                          </button>
                        )}

                        {anchor && (
                          <Button {...anchor(row)}>
                            <Button.Icon>
                              <Icon name="arrow-right" className={styles['-anchor-icon']} />
                            </Button.Icon>
                          </Button>
                        )}
                      </td>
                    ) : null}
                  </tr>
                  {expandable && isRowExpandable(row) ? (
                    <tr
                      id={hash(`expanded-${i}`)}
                      data-expanded-row=""
                      aria-hidden={isRowExpanded(i) ? 'false' : 'true'}
                      data-testid={
                        props['data-testid']
                          ? `${props['data-testid']}-expandable-tr-content`
                          : undefined
                      }
                    >
                      <td data-expanded-column="" colSpan={columns.length + 1}>
                        <div data-expanded-content="">{expandable(row)}</div>
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })
          ) : (
            <tr>
              <td
                className={styles['-empty']}
                colSpan={
                  expandable || actions || anchor
                    ? columns.length + 1
                    : columns.length
                }
              >
                {noRowsMessage}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;

Table.defaultProps = {
  noRowsMessage: 'Neste momento, estamos sem dados para mostrar aqui.',
  linesPerCell: 1,
};
