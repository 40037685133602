import styles from '@sicredi/styles/_star-rating.scss';
import iconStyles from './star-rating.scss';
import cn from 'clsx';
import React, { useState } from 'react';
import { Icon } from '../icon';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
  value: number;
  size?: 'small' | 'medium' | 'large';
  readOnly?: boolean;
  showTitle: boolean;
  onChange?: (value: any) => void;
}

const StarRating: React.FC<Props> = ({
  value,
  size,
  readOnly,
  showTitle,
  className,
  onChange,
  ...props
}) => {
  const [rating, setRating] = useState<number>(value);
  const [hover, setHover] = useState<number>(0);

  const getStatus = (value: number) => {
    switch (value) {
      case 1:
        return 'Muito ruim';
      case 2:
        return 'Ruim';
      case 3:
        return 'Indiferente';
      case 4:
        return 'Bom';
      case 5:
        return 'Muito bom';
      default:
        return '';
    }
  };

  return (
    <>
      <div
        className={cn(className, {
          [styles['star-rating']]: true,
          [styles['-small']]: size === 'small',
          [styles['-medium']]: size === 'medium',
          [styles['-large']]: size === 'large',
        })}
      >
        <div className={styles['rating']} {...props}>
          {[...Array(5)].map((_star, i) => {
            const index = i + 1;

            const handleChange = (value: number) => {
              setRating(value);
              onChange && onChange(value);
            };

            const selected = index <= (hover || rating);

            return (
              <label key={`rating_key_${i}`} className={styles['stars']}>
                <input
                  className={styles['input']}
                  type="radio"
                  name={`rating_${i}`}
                  value={index}
                  onClick={() => (!readOnly ? handleChange(index) : '')}
                />
                <Icon
                  name={selected ? 'star-filled' : 'star'}
                  size={64}
                  className={cn({
                    [styles['star']]: true,
                    [styles['-readOnly']]: readOnly,
                    [iconStyles['star-icon']]: true,
                  })}
                  onMouseEnter={() => (!readOnly ? setHover(index) : '')}
                  onMouseLeave={() => (!readOnly ? setHover(0) : '')}
                />
                {showTitle ? <p className={styles['caption']}>{index}</p> : ''}
              </label>
            );
          })}
        </div>
        {showTitle ? (
          <div className={styles['title']}>{getStatus(rating)}</div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

StarRating.defaultProps = {
  size: 'medium',
};

export default StarRating;
