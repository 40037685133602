import React from 'react';
import { render } from 'react-dom';
import { Loader } from '@sicredi/react';

/* Descomentar caso utilize a autenticação da plataforma. Remover se utilizar a do CAS */
/*import {
  createEmployeeOpenIdClient,
  createSalesForceEmployeeOpenIdClient,
} from "@sicredi/authentication";
import { APPLICATION } from "#/constants";*/

import './global.scss';
import App from './containers/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const ROOT = document.getElementById('sicredi-cookies-root')!;

function unmountRender(element: React.ReactElement) {
  render(element, ROOT);
}

unmountRender(<Loader show fullScreen />);

unmountRender(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
