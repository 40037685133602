import * as React from 'react';
import { mask, unmask, Masks, CreateNumberMaskProps, CurrencyConfig } from '@sicredi/mask';

import Input, { Props as InputProps } from '../input/Input';
import { normalizeNumber } from '../utils';

export interface Props extends InputProps {
  mask: Masks;
  preserveChars?: string[];
  config?: CreateNumberMaskProps | CurrencyConfig;
}

export interface CompoundedMaskedInput
  extends React.ForwardRefExoticComponent<
    Props & React.RefAttributes<HTMLInputElement>
  > {
  mask(str: any, mask: Masks, config?: {}): string;
  unmask(str: any, preserveChars?: string[]): string;
}

const MaskedInput = React.forwardRef<HTMLInputElement, Props>(
  (
    { mask: maskPattern, value, onChange, preserveChars, config, ...props },
    ref
  ) => {
    const [internalValue, setInternalValue] = React.useState('');

    const preserveCharsConfig =
      config && config['allowNegative']
        ? (preserveChars || []).concat('-', '+')
        : preserveChars;

    function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
      const isWhiteSpace = internalValue[internalValue.length - 1] === ' ';
      const unmaskedValue = unmask(e.target.value, preserveCharsConfig);
      const unmaskedInternalValue = unmask(internalValue, preserveCharsConfig);

      const nextValue =
        unmaskedValue === unmaskedInternalValue
          ? e.target.value.slice(0, isWhiteSpace ? -2 : -1)
          : e.target.value;

      e.target.value =
        maskPattern === 'CURRENCY'
          ? mask(
              normalizeNumber(unmask(nextValue, preserveCharsConfig)),
              maskPattern,
              config
            )
          : mask(unmask(nextValue, preserveCharsConfig), maskPattern, config);

      setInternalValue(e.target.value);

      onChange && onChange(e);
    }

    return (
      <Input
        ref={ref}
        value={
          value !== undefined
            ? mask(unmask(value, preserveCharsConfig), maskPattern, config)
            : internalValue
        }
        onChange={handleChange}
        {...props}
      />
    );
  }
) as CompoundedMaskedInput;

MaskedInput.mask = mask;
MaskedInput.unmask = unmask;

export default MaskedInput;
