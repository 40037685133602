import { useEffect } from 'react';
import Cookies from 'js-cookie';

import { UserConsentPT } from '../../Interfaces/UserConsent.Interface';

export const useDeleteCookiesDisabled = () => {
  function cookiesToRemove() {
    const consent = JSON.parse(
      localStorage.getItem('CookiesDomains') as any,
    ) as UserConsentPT;

    const categoriesDisabled = consent?.categoriaDtos?.filter((category) => {
      return category.habilitado === false;
    });

    categoriesDisabled?.forEach((category) => {
      category.cookieDtos.forEach((cookie) => {
        Cookies.remove(cookie.nome);

        if (Cookies.get(cookie.nome)) {
          Cookies.remove(cookie.nome, {
            domain: cookie.domain,
            path: cookie.path,
          });
        }
      });
    });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (Cookies.get('USERCUUID')) {
        setTimeout(cookiesToRemove, 3000);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
};
