import Cookies from 'js-cookie';
import { createContext, ReactNode, useContext, useState } from 'react';

interface BannerContextType {
  isBannerVisible: string;
  setIsBannerVisible: React.Dispatch<React.SetStateAction<string>>;
}

const initialValue: BannerContextType = {
  isBannerVisible: '',
  setIsBannerVisible: () => {},
};

export const BannerContext = createContext<BannerContextType>(initialValue);

interface BannerProviderProps {
  children: ReactNode;
}

export const BannerProvider = ({ children }: BannerProviderProps) => {
  const [isBannerVisible, setIsBannerVisible] = useState('');

  return (
    <BannerContext.Provider value={{ isBannerVisible, setIsBannerVisible }}>
      {children}
    </BannerContext.Provider>
  );
};

export const BANNER_HIDDEN_CLASS = 'banner-hidden';

export const useBanner = () => {
  const { isBannerVisible, setIsBannerVisible } = useContext(BannerContext);

  const bannerHidden = (isModalVisible = false) => {
    if (isModalVisible) {
      setIsBannerVisible(BANNER_HIDDEN_CLASS);
    }

    if (Cookies.get('USERCUUID')) {
      setIsBannerVisible(BANNER_HIDDEN_CLASS);
    }

    if (!Cookies.get('USERCUUID')) {
      setIsBannerVisible('');
    }
  };

  return {
    isBannerVisible,
    setIsBannerVisible,
    bannerHidden,
  };
};
