import React from 'react';
import clsx from 'clsx';
import styles from '@sicredi/styles/_header.scss';
import { Avatar } from '..';

export interface Props {
  children?: React.ReactNode;
  title: string;
  caption?: string;
  avatar?: string;
  className?: string;
  withIcon?: boolean;
}

const Header: React.FC<Props> = ({
  title,
  caption,
  avatar,
  className,
  withIcon,
  ...props
}) => {
  return (
    <div
      className={clsx(styles['sicredi-header'], className)}
      role="header"
      aria-label={props['aria-label'] ? props['aria-label'] : title}
      {...props}
    >
      {(withIcon || avatar) && (
        <Avatar
          src={avatar}
          size="x-small"
          className={styles['avatar']}
          data-testid={
            props['data-testid']
              ? `${props['data-testid']}-avatar`
              : 'header-avatar'
          }
        />
      )}

      <div className={styles['content']}>
        {caption && (
          <p
            className={styles['subtitle']}
            data-testid={
              props['data-testid']
                ? `${props['data-testid']}-caption`
                : 'header-caption'
            }
          >
            {caption}
          </p>
        )}
        <h4
          className={styles['title']}
          data-testid={
            props['data-testid']
              ? `${props['data-testid']}-title`
              : 'header-title'
          }
        >
          {title}
        </h4>
      </div>
    </div>
  );
};

export default Header;
