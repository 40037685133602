import { useState, useLayoutEffect } from "react";

let id = 0;
const generateId = () => ++id;

/**
 * Autogenerate IDs to facilitate WAI-ARIA
 * @param prefix - the prefix for the generated id
 */
export const useId = (prefix?: string) => {
  const [id, setId] = useState<string>();

  useLayoutEffect(() => {
    setId(String(generateId()));
  }, []);

  return prefix ? `${prefix}-${id}` : id;
}
