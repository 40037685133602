import { APPLICATION } from './../../constants';
import axios from 'axios';
import { CookiesConsentPayload } from '../Interfaces/CookiesConsent.Interface';
import { ConsentId } from '../Types/ConsentId.Type';

export async function postConsent(body: CookiesConsentPayload) {
  const response = await axios.post<ConsentId>(
    `${APPLICATION.BFF_URL}consentimentos`,
    body,
  );

  return response.data;
}
