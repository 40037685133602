import * as React from 'react';
import styles from '@sicredi/styles/_button.scss';
import cn from 'clsx';

import Icon, { Props as IconProps, POSITION } from './Icon';

export interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  as?: any; // TODO: add correct typing
  block?: boolean;
  ghost?: boolean;
  square?: boolean;
  appearance?: 'primary' | 'danger' | 'link';
  size?: 'large' | 'small';
  [key: string]: any;
}

export interface CompoundedButton extends React.FC<Props> {
  Icon: React.FC<IconProps>;
}

function enhanceChildren(children: React.ReactNode): React.ReactNode {
  return React.Children.map(children, (component, i) => {
    if (
      React.isValidElement(component) &&
      (component.type['displayName'] || component.type['name']) === Icon.name
    ) {
      const props: IconProps = {
        position: i === 0 ? POSITION.BEFORE : POSITION.AFTER,
      };

      return React.cloneElement(
        component,
        React.Children.count(children) > 1 ? props : {}
      );
    }

    return <span className={styles.text}>{component}</span>;
  });
}

const Button: CompoundedButton = ({
  as: Component,
  block,
  ghost,
  square,
  disabled,
  children,
  className,
  appearance,
  size,
  ...props
}) => (
  <Component
    type={Component === 'button' ? 'button' : undefined}
    tabIndex={disabled ? -1 : 0}
    disabled={disabled}
    className={cn(styles['sicredi-button'], className, {
      [styles['-block']]: block,
      [styles['-ghost']]: ghost,
      [styles['-square']]: square,
      [styles['-primary']]: appearance === 'primary',
      [styles['-danger']]: appearance === 'danger',
      [styles['-link']]: appearance === 'link',
      [styles['-small']]: size === 'small',
      [styles['-large']]: size === 'large',
    })}
    aria-disabled={disabled ? 'true' : 'false'}
    {...props}
  >
    {enhanceChildren(children)}
  </Component>
);

Button.defaultProps = {
  as: 'button',
};

Button.Icon = Icon;

export default Button;
