import Cookies from 'js-cookie';
import { Category, Domain } from '../../Interfaces/Domain.Interface';
import {
  UserConsentCategoryPT,
  UserConsentPT,
} from '../../Interfaces/UserConsent.Interface';

export const createCookies = (userId: string) => {
  Cookies.set('USERCUUID', userId, { expires: 400 });
};

export const updatedDomain = (updateDomainDate: string | null) => {
  if (!updateDomainDate) {
    return;
  }

  if (!localStorage.getItem('DominioAtualizado')) {
    localStorage.setItem('DominioAtualizado', updateDomainDate);
    return;
  }

  if (
    updateDomainDate.trim().toUpperCase() ===
    localStorage.getItem('DominioAtualizado')?.trim().toUpperCase()
  ) {
    return;
  }

  if (
    updateDomainDate.trim().toUpperCase() !==
    localStorage.getItem('DominioAtualizado')?.trim().toUpperCase()
  ) {
    Cookies.remove('USERCUUID');
    localStorage.clear();
  }
};

export const isCategoryEnabledInTheConsent = (
  categoryId: string,
  consent: UserConsentCategoryPT | null,
) => {
  if (!consent) {
    return false;
  }
  const categoryConsentData = consent.categoriaCookieDtos?.find((category) => {
    return category.categoriaDto.uuid === categoryId;
  })?.habilitado;

  return categoryConsentData ?? false;
};

const isCategoryEnabled = (categoryId: string, categories: Array<Category>) => {
  const categoriesPermisson = categories.find((category) => {
    return category.uuid === categoryId;
  })?.able;

  return categoriesPermisson ?? false;
};

export const saveDataInTheLocalStorage = (
  domain: Domain | null,
  categories: Array<Category> = [],
  consent: UserConsentCategoryPT | null = null,
) => {
  if (!domain) {
    return;
  }

  updatedDomain(domain.updatedDomain ?? consent?.dominioAtualizado);

  const domainPT: UserConsentPT = {
    uuid: domain.uuid,
    consentimentoUUID: consent?.uuid ?? '',
    organizacao: domain.organization,
    dominioAtualizado: domain.updatedDomain,
    url: domain.url,
    _links: domain.links,
    categoriaDtos: domain.categoryDtos.map((category) => {
      return {
        uuid: category.uuid,
        nome: category.name,
        descricao: category.description,
        habilitado: !consent
          ? isCategoryEnabled(category.uuid, categories)
          : isCategoryEnabledInTheConsent(category.uuid, consent),
        cookieDtos: category.cookieDtos.map((cookie) => {
          return {
            uuid: cookie.uuid,
            nome: cookie.name,
            tipo: cookie.type,
            domain: cookie.domain,
            descricao: cookie.description,
            path: cookie.path,
            valor: cookie.value,
            httpOnly: cookie.httpOnly,
            seguro: cookie.safe,
            categoria: cookie.category,
          };
        }),
      };
    }),
    termoCookieDto: {
      uuid: domain.cookieDtoTerm.uuid,
      termoDetalhado: domain.cookieDtoTerm.detailedTerm,
      termoLink: domain.cookieDtoTerm.linkTerm,
      termoResumido: domain.cookieDtoTerm.summedUpTerm,
    },
  };

  localStorage.setItem('CookiesDomains', JSON.stringify(domainPT));
};
