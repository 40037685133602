import * as React from 'react';
import styles from '@sicredi/styles/_dropdown-menu.scss';
import { MenuItem, MenuStateReturn } from 'reakit/Menu';
export interface Props {
  children?: React.ReactNode;
  as?: any; // TODO: add correct typing
  icon?: React.ReactNode;
  onClick: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  menuState: MenuStateReturn;
  [key: string]: any;
}

const Item: React.FC<Props> = ({
  as,
  icon,
  children,
  menuState,
  ...props
}) => {
  return (
    <MenuItem
      as={as}
      data-item=""
      data-testid={props['data-testid'] ? `${props['data-testid']}` : undefined}
      {...menuState}
      {...props}
    >
      {icon && <span className={styles['icon']}>{icon}</span>}
      {children}
    </MenuItem>
  );
};

Item.defaultProps = {
  as: 'a',
};

export default Item;
