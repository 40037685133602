import * as React from 'react';
import cn from 'clsx';
import { hash } from '../utils';
import styles from '@sicredi/styles/_switch.scss';
import { Icon } from '../icon';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode;
  title?: string;
  appearance?: 'default' | 'alert';
  caption?: string;
  icon?: React.ReactNode;
}

const Switch = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      title,
      icon,
      value,
      checked,
      caption,
      children,
      required,
      onChange,
      disabled,
      readOnly,
      className,
      appearance,
      ...props
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = React.useState(false);

    function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
      e.persist();

      setInternalValue(!internalValue);

      onChange && onChange(e);
    }

    return (
      <React.Fragment>
        <div
          className={cn(
            styles['sicredi-switch'],
            styles[`-${appearance || 'default'}`],
            className,
            { [styles['-disabled']]: disabled },
            { [styles['-full-mode']]: !!title }
          )}
          {...props}
        >
          {!!title && (
            <div className={styles['wrapper']}>
              <h3 className={styles['title']}>
                {title} {icon}
              </h3>
              {caption && <p className={styles['caption']}>{caption}</p>}
            </div>
          )}
          <input
            id={`${hash(name)}-switch`}
            ref={ref}
            name={name}
            type="checkbox"
            role="switch"
            value={value}
            checked={checked !== undefined ? checked : internalValue}
            disabled={disabled}
            tabIndex={disabled || readOnly ? -1 : 0}
            onChange={handleChange}
            readOnly={readOnly}
            className={styles['input']}
            data-input=""
            data-testid={
              props['data-testid'] ? props['data-testid'] : undefined
            }
            aria-checked={checked !== undefined ? checked : internalValue}
            aria-disabled={disabled ? 'true' : 'false'}
            aria-labelledby={`${hash(name)}-switch-label`}
          />
          <label
            id={`${hash(name)}-switch-label`}
            htmlFor={`${hash(name)}-switch`}
            className={styles['label']}
            data-label=""
          >
            <span className={styles['icon']}>
              <Icon name="switch" />
            </span>
          </label>
        </div>
      </React.Fragment>
    );
  }
);

export default Switch;
