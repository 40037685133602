import * as React from 'react';

import { Icon } from '../icon';
import { Title } from '..';
import { hash } from '../utils';
import styles from '@sicredi/styles/_list.scss';

export interface Props {
  children?: React.ReactNode;
  label: string;
  heading?: React.ReactNode;
  body?: React.ReactNode;
  controls?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement> | number) => void;
  expanded?: boolean;
  checkbox?: React.ReactElement;
  transparent?: boolean;
}

const Item: React.FC<Props> = ({
  label,
  heading,
  body,
  controls,
  onClick,
  children,
  expanded,
  checkbox,
  transparent,
  ...props
}) => (
  <li data-item className={transparent ? styles['-transparent'] : ''}>
    {checkbox &&
      React.cloneElement(checkbox, {
        className: styles['checkbox'],
      })}
    <div className={styles['item']}>
      <div role="heading" data-heading="" aria-level={3}>
        <Title
          as="h4"
          className={styles['-title']}
          data-testid={
            props['data-testid'] ? `${props['data-testid']}-label` : undefined
          }
        >
          {label}
        </Title>
        <div className={styles['controls']}>
          {heading}
          <button
            type="button"
            onClick={onClick}
            tabIndex={0}
            data-button=""
            aria-controls={`${hash(label)}-list-content`}
            aria-expanded={expanded ? 'true' : 'false'}
            {...props}
          >
            <Icon
              name="chevron-down"
              className={styles['icon']}
              data-icon=""
              data-testid={
                props['data-testid']
                  ? `${props['data-testid']}-icon`
                  : undefined
              }
            />
          </button>
        </div>
      </div>
      {(body || controls) && <div className={styles['body']}>
        {body && <div className={styles['content']}>{body}</div>}
        {controls && <div className={styles['controls']}>{controls}</div>}
      </div>}
      {children && <div
        id={`${hash(label)}-list-content`}
        className={styles['content']}
        aria-hidden={expanded ? 'false' : 'true'}
        data-testid={
          props['data-testid'] ? `${props['data-testid']}-content` : undefined
        }
        data-content=""
      >
        {children}
      </div>}
    </div>
  </li>
);

export default Item;
