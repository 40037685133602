import * as React from 'react';
import { Title } from '../title';
import { Paragraph } from '../paragraph';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import cn from 'clsx';
import styles from '@sicredi/styles/_overlay.scss';
import variables from '@sicredi/styles/_variables.scss';

export interface Props
  extends Pick<ReactModalProps, Exclude<keyof ReactModalProps, 'isOpen'>> {
  children?: React.ReactNode;
  show: boolean;
  title: string;
  helperText?: string;
  onClose?: () => void;
}
const Overlay: React.FC<Props> = ({
  title,
  helperText,
  show,
  onClose,
  className,
  children,
  ...props
}: Props) => {
  const [contentRef, setContentRef] = React.useState<HTMLDivElement>();

  React.useEffect(() => {
    if (!contentRef) return;

    setTimeout(() => {
      contentRef.focus();
    }, parseFloat(variables.animationTiming) * 1000);
  }, [contentRef]);

  return (
    <ReactModal
      isOpen={show}
      className={styles['overlay']}
      contentRef={setContentRef}
      onRequestClose={onClose}
      closeTimeoutMS={parseFloat(variables.animationTiming) * 1000}
      overlayClassName={{
        base: cn(styles['sicredi-overlay'], className),
        afterOpen: styles['-show'],
        beforeClose: styles['-hide'],
      }}
      bodyOpenClassName={styles['sicredi-overlay-show']}
      ariaHideApp={false}
      {...props}
    >
      <div className={styles['sicredi-overlay-header']}>
        <Title as="h3" data-testid="title">
          {title}
        </Title>
        <Paragraph data-testid="helperText">{helperText}</Paragraph>
      </div>

      {children && <div data-testid="content">{children}</div>}
    </ReactModal>
  );
};
export default Overlay;
