import React from 'react';
import cn from 'clsx';
import styles from '@sicredi/styles/_labelled-value.scss';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  label: React.ReactNode;
  value: React.ReactNode;
}

const Item: React.FC<Props> = ({
  label,
  value,
  className,
  ...props
}) => {
  return (
    <div className={cn(styles['item'], className)} {...props}>
      <dt>{label}</dt>
      <dd>{value}</dd>
    </div>
  );
};

export default Item;
