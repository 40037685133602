import styles from '@sicredi/styles/_paragraph.scss';
import cn from 'clsx';
import React from 'react';

export interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
  children?: React.ReactNode;
  size?: 'x-small' | 'small' | 'medium' | 'large' | 'x-large' | 'xx-large';
  weight?: 'x-light' | 'light' | 'normal' | 'semi-bold' | 'bold';
}

const Paragraph: React.FC<Props> = ({
  size = 'medium',
  weight = 'light',
  className,
  children,
  ...props
}) => (
  <p
    className={cn(styles['sicredi-paragraph'], className, {
      [styles[`-${size}`]]: !!size,
      [styles[`-${weight}`]]: !!weight,
    })}
    {...props}
  >
    {children}
  </p>
);

export default Paragraph;
