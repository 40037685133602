import * as React from 'react';
import cn from 'clsx';
import styles from '@sicredi/styles/_dropdown-menu.scss';

import Item, { Props as MenuItemProps } from './Item';
import { useMenuState, Menu, MenuButton } from 'reakit/Menu';

export interface Schema extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  triggerProps: {
    as?: any;
    children?: React.ReactNode;
  };
  onChange?: (value: any) => void;
  portal?: boolean;
}
export interface AriaLabelProps extends Schema {
  'aria-label': string;
}

export interface AriaLabelledbybyProps extends Schema {
  'aria-labelledby': string;
}

export type Props = AriaLabelProps | AriaLabelledbybyProps;

export interface CompoundedDropdownMenu extends React.FC<Props> {
  Item: React.FC<Partial<MenuItemProps>>;
}

const DropdownMenu: CompoundedDropdownMenu = ({
  children,
  onChange,
  className,
  triggerProps,
  portal,
  ...props
}) => {
  const menu = useMenuState({ modal: portal });

  const items = React.Children.toArray(children).filter(
    Boolean
  ) as React.ReactElement<MenuItemProps>[];

  React.useEffect(() => {
    onChange && onChange({ visible: menu.visible });
  }, [menu.visible]);

  function enhanceClick(
    onClick?: Function
  ): (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void {
    return event => {
      onClick && onClick(event);
      menu.hide();
    };
  }

  return (
    <div className={cn(styles['sicredi-dropdown'], className)} {...props}>
      <MenuButton {...menu} {...triggerProps} />

      <Menu
        className={styles['sicredi-dropdown-menu']}
        {...menu}
        aria-label={props['aria-label']}
      >
        {items.map(item =>
          React.cloneElement(item, {
            menuState: { ...menu },
            onClick: enhanceClick(item.props.onClick),
          })
        )}
      </Menu>
    </div>
  );
};

DropdownMenu.Item = Item;

export default DropdownMenu;
