import { getDomain } from '../common/Requests/GetDomain.Request';

export const getDomainService = async (domain_: string) => {
  const domain = await getDomain(domain_);

  const strictNecessary = 'Estritamente Necessários'.trim().toLowerCase();

  const domainData = {
    uuid: domain.uuid,
    organization: domain.organizacao,
    updatedDomain: domain.dominioAtualizado,
    url: domain.url,
    links: domain._links,
    categoryDtos: domain.categoriaDtos.map((item) => {
      return {
        uuid: item.uuid,
        name: item.nome,
        description: item.descricao,
        able: item.nome.trim().toLowerCase() === strictNecessary,
        cookieDtos: item.cookieDtos.map((item) => {
          return {
            uuid: item.uuid,
            name: item.nome,
            type: item.tipo,
            domain: item.domain,
            description: item.descricao,
            path: item.path,
            value: item.valor,
            httpOnly: item.httpOnly,
            safe: item.seguro,
            category: item.categoria,
          };
        }),
      };
    }),
    cookieDtoTerm: {
      uuid: domain.termoCookieDto.uuid,
      summedUpTerm: domain.termoCookieDto.termoResumido,
      linkTerm: domain.termoCookieDto.termoLink,
      detailedTerm: domain.termoCookieDto.termoDetalhado,
    },
  };

  return domainData;
};
