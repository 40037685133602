import { useCategory } from '../../common/Context/Category.Context';
import { Spacing, Switch } from '@sicredi/react';
import './index.scss';

export const AllCookiesSwitch = () => {
  const { setAllCategories, selectAllCategories } = useCategory();

  return (
    <div>
      <Spacing appearance="medium" />
      <Switch
        data-testid="all-cookies-switch"
        id="all-cookies-switch"
        name="all-cookies-switch"
        className="all-cookies-switch"
        title="Permitir todos os cookies"
        checked={selectAllCategories}
        onChange={(event) => {
          setAllCategories(event.target.checked);
        }}
      />
      <Spacing appearance="medium" />
    </div>
  );
};
