import * as React from 'react';
import styles from '@sicredi/styles/_breadcrumb.scss';
import { Icon } from '../icon';

export interface PrivateProps {
  children?: React.ReactNode;
  as?: any; // TODO: add correct typing
  active?: boolean;
  [key: string]: any;
}

export interface Props
  extends Pick<PrivateProps, Exclude<keyof PrivateProps, 'active'>> {}

const Item: React.FC<PrivateProps> = ({
  as: Component,
  active,
  children,
  ...props
}) => (
  <li
    data-item=""
    className={styles['item']}
    aria-current={active ? 'page' : undefined}
    data-testid={props['data-testid'] ? `${props['data-testid']}` : undefined}
  >
    <Component
      {...props}
      data-testid={
        props['data-testid'] ? `${props['data-testid']}-children` : undefined
      }
    >
      {children}
    </Component>
    {!active && <Icon name="chevron-right" data-icon="" />}
  </li>
);

Item.defaultProps = {
  as: 'span',
};

export default Item;
