import * as React from 'react';

import Button, { Props as ButtonProps } from '../button/Button';
import { Icon } from '../icon';
import styles from '@sicredi/styles/_bottom-navigation.scss';

export interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  buttonDisabled?: boolean;
  buttonLabel?: 'Avançar' | 'Finalizar';
  singleNavigation?: boolean;
  actionLeft?: ButtonAction;
  actionRight?: ButtonAction;
}

interface ButtonAction extends ButtonProps {
  href?: string;
  to?: string;
  target?: string;
}

const BottomNavigation: React.FC<Props> = ({
  buttonLabel = 'Avançar',
  singleNavigation = false,
  buttonDisabled = false,
  actionLeft,
  actionRight,
  ...props
}) => {
  return (
    <div className={styles['sicredi-bottom-navigation']} {...props}>
      <div className={styles['wrapper']}>
        <Button 
          {...(props["data-testid"] ? {"data-testid":`${props["data-testid"]}-button-left`} : {})}
          {...actionLeft}>
          <Button.Icon>
            <Icon name="chevron-left" />
          </Button.Icon>
          Voltar
        </Button>

        {!singleNavigation && (
          <Button 
            {...(props["data-testid"] ? {"data-testid":`${props["data-testid"]}-button-right`} : {})}
            {...actionRight} disabled={buttonDisabled}>
            {buttonLabel}
            <Button.Icon>
              <Icon name="chevron-right" />
            </Button.Icon>
          </Button>
        )}
      </div>
    </div>
  );
};

export default BottomNavigation;
