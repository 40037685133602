import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import debounce from "debounce";
import { Tooltip } from '../../tooltip';
import { Props as TooltipProps } from '../../tooltip/Tooltip';
import styles from './Ellipsis.scss';

const DEBOUNCE_TIME = 250;
export interface Props {
  children?: React.ReactNode;
  lineClamp?: number;
  placement?: TooltipProps["placement"];
}

const Ellipsis: React.FC<Props> = ({
  children,
  lineClamp,
  placement = "top",
}) => {
  const el = useRef<HTMLDivElement>(null);

  const [hasEllipsis, setHasEllipsis] = useState(false);

  useEffect(() => {
    if (!el.current) return;

    const handleHasEllipsis = debounce(
      () => setHasEllipsis(!!el.current && el.current.offsetHeight < el.current.scrollHeight),
      DEBOUNCE_TIME,
    );

    handleHasEllipsis();
  }, [children]);

  const enhancedChildren = useMemo(() => {
    return (
      <Fragment>
        {React.Children.map(children, child => (
          <div
            ref={el}
            className={styles['sicredi-ellipsis']}
            style={{
              WebkitLineClamp: lineClamp,
              lineBreak: lineClamp === 1 ? "anywhere" : "normal",
            }}
          >
            {child}
          </div>
        ))}
      </Fragment>
    );
  }, [children]);

  return hasEllipsis ? (
    <Tooltip placement={placement} className={styles['tooltip']} tip={children}>
      {enhancedChildren}
    </Tooltip>
  ) : (
    enhancedChildren
  );
};

Ellipsis.defaultProps = {
  lineClamp: 1
};

export default Ellipsis;
