import React, { Fragment } from 'react';
import cn from 'clsx';
import styles from '@sicredi/styles/_layout.scss';

import Aside, { Props as AsideProps } from './Aside';
import Wrapper, { Props as WrapperProps } from './Wrapper';
import Content, { Props as ContentProps } from './Content';
import Container, { Props as ContainerProps } from './Container';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

export interface CompoundedLayout extends React.FC<Props> {
  Aside: React.FC<AsideProps>;
  Wrapper: React.FC<WrapperProps>;
  Content: React.FC<ContentProps>;
  Container: React.FC<ContainerProps>;
}

const Layout: CompoundedLayout = ({ children, className, ...props }) => {
  const isFull = !React.Children.toArray(children).some(component => {
    return (
      React.isValidElement(component) &&
      (component.type['displayName'] || component.type['name']) ===
        Container.name
    );
  });

  return (
    <Fragment>
      <div
        className={cn(styles['sicredi-layout'], className, {
          [styles['-full']]: isFull,
        })}
        {...props}
      >
        {children}
      </div>
    </Fragment>
  );
};

Layout.Aside = Aside;
Layout.Wrapper = Wrapper;
Layout.Content = Content;
Layout.Container = Container;

export default Layout;
