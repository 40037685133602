import * as React from 'react';
import styles from '@sicredi/styles/_toast.scss';
import cn from 'clsx';
import { Icon } from '../icon';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  show?: boolean;
  onClose: () => void;
  className?: string;
  appearance?: 'success' | 'warning' | 'error';
}

const Toast: React.FC<Props> = ({
  show,
  onClose,
  children,
  className,
  appearance,
  ...props
}) => {
  return (
    <div
      role="alert"
      tabIndex={0}
      className={cn(styles['sicredi-toast'], className, {
        [styles['-show']]: show,
        [styles['-error']]: appearance === 'error',
        [styles['-success']]: appearance === 'success',
        [styles['-warning']]: appearance === 'warning',
      })}
      aria-live="polite"
      data-toast=""
      {...props}
    >
      {children}
      {onClose && (
        <button
          type="button"
          onClick={onClose}
          tabIndex={0}
          className={styles['close']}
          aria-label="Fechar"
          data-button=""
        >
          <Icon name="close" className={styles['icon']} />
        </button>
      )}
    </div>
  );
};

export default Toast;
