
import styles from '@sicredi/styles/_simulation-box.scss';
import * as React from 'react';
import { Spacing } from '../spacing';
import { Title } from '../title';

export interface Props {
  children?: React.ReactNode;
  label: React.ReactNode;
  caption?: React.ReactNode;
}

const Item: React.FC<Props> = ({
  label,
  caption,
  children,
  ...props
}) => (
    <li
      className={styles['item']}
      {...props}
    >
      <Title as="h3" size= "medium" className={styles['label']}>{label}</Title>
      <Title as="h3" size="medium" weight="semi-bold">{children}</Title>

      {caption &&
        <>
          <Spacing height={'4px'} />
          <small className={styles['caption']}>{caption}</small>
        </>
      }
    </li>
  );

  export default Item;
