import { getConsentService } from './getConsentService';
import { UserConsentCreateConfig } from '../common/Interfaces/UserConsent.Interface';
import { postConsent } from '../common/Requests/PostConsent.Request';
import { Domain } from '../common/Interfaces/Domain.Interface';

export const createConsentService = async (
  userConsent: UserConsentCreateConfig,
  domain_: string,
) => {
  try {
    const { uuid } = await postConsent(userConsent.body);
    await getConsentService(
      userConsent.domain,
      userConsent.user,
      domain_,
      uuid,
    );
  } catch (err) {
    console.log(err);
  }
};
