import { getEnv } from './utils/getEnv';

export const APPLICATION = {
  ID: process.env.REACT_APP_ID,
  NAME: `@${process.env.REACT_APP_ID.replace('-', '_').toUpperCase()}`,
  EXTERNAL: ['WEB_CRM', 'WEB_PUBLIC'].includes(
    process.env.REACT_APP_JOB_TEMPLATE_TYPE,
  ),
  get BFF_URL() {
    let enviroment = undefined;
    let sicrediRoot = document.getElementById('sicredi-cookies-root');
    if (sicrediRoot !== undefined) {
      enviroment = sicrediRoot?.getAttribute('data-enviroment');

      switch (enviroment) {
        case 'DEV':
          return 'https://lgpd-consentimento-cookies-api.dev-sicredi.in/api/v1/consentimento-cookies/';
        case 'TST':
          return 'https://lgpd-consentimento-cookies-api.tst-sicredi.in/api/v1/consentimento-cookies/';
        case 'UAT':
          return 'https://lgpd-consentimento-cookies-api.uat-sicredi.in/api/v1/consentimento-cookies/';
        case 'PRD':
          return 'https://api.sicredi.io/api/v1/public/consentimento-cookies/';
        default:
          console.error(
            'A Atributo "data-environment" da biblioteca de cookies não está setado ou é inválido.',
          );
          break;
      }
    }
  },

  get ENV() {
    return getEnv();
  },

  get DOMINIO() {
    let sicrediRoot = document.getElementById('sicredi-cookies-root');
    let dominio = sicrediRoot?.getAttribute('data-dominiouuid');
    return dominio as string;
  },
};

export const PAGE = {
  ROOT: () => '/',
};
