import * as React from 'react';
import cn from 'clsx';

import styles from '@sicredi/styles/_card.scss';
import { Spacing } from '../spacing';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
  as?: any;
  [key: string]: any;
  footer?: React.ReactNode;
  header?: {
    leftComponent?: React.ReactNode;
    rightComponent?: React.ReactNode;
  };
  appearance?: 'primary' | 'bordered';
  status?: 'info' | 'success' | 'warning' | 'danger';
  footerProps?: React.HTMLAttributes<HTMLDivElement>;
}

const Card: React.FC<Props> = ({
  footer,
  children,
  className,
  appearance,
  status,
  as: Component = 'button',
  header = {
    leftComponent: null,
    rightComponent: null,
  },
  footerProps,
  ...props
}) => {
  return (
    <div
      className={cn(styles['sicredi-card'], className, {
        [styles['-primary']]: appearance === 'primary',
        [styles['-bordered']]: appearance === 'bordered',
        [styles['-info']]: appearance === 'bordered' && status === 'info',
        [styles['-success']]: appearance === 'bordered' && status === 'success',
        [styles['-warning']]: appearance === 'bordered' && status === 'warning',
        [styles['-danger']]: appearance === 'bordered' && status === 'danger',
      })}
    >
      <Component className={styles['content']} {...props}>
        {(!!header.leftComponent || !!header.rightComponent) && (
          <React.Fragment>
            <div
              className={styles['header']}
              data-testid={
                props['data-testid'] && `${props['data-testid']}-header`
              }
            >
              {header.leftComponent}
              {header.rightComponent}
            </div>
            <Spacing appearance="small" />
          </React.Fragment>
        )}
        {children}
      </Component>

      {!!footer && (
        <div
          className={styles['footer']}
          data-testid={props['data-testid'] && `${props['data-testid']}-footer`}
          {...footerProps}
        >
          {footer}
        </div>
      )}
    </div>
  );
};

export default Card;
