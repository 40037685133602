/* istanbul ignore file */
import React, { Suspense, lazy, useMemo } from 'react';
import { pascalCase } from 'change-case';
import variables from '@sicredi/styles/_variables.scss';

export const IconNames = [
  'wheelchair',
  'apps',
  'biometry',
  'clock',
  'copy',
  'edit',
  'filter',
  'home',
  'list-bullet',
  'padlock-open',
  'padlock-closed',
  'microphone',
  'notification',
  'play',
  'plus',
  'refresh',
  'search',
  'settings',
  'share',
  'trash',
  'refresh-web',
  'sound',
  'wifi',
  'viewer',
  'viewer-off',
  'flash',
  'flash-off',
  'upload',
  'pin',
  'pin-filled',
  'gallery',
  'actions',
  'exit',
  'checkbox-filled',
  'checkbox',
  'radio-filled',
  'radio',
  'stepper-increase',
  'stepper-decrease',
  'close',
  'star',
  'star-filled',
  'like',
  'dislike',
  'mail-open',
  'mail-closed',
  'check',
  'undo',
  'contrast-night',
  'contrast-day',
  'order-no-position',
  'order-down',
  'order-up',
  'chevron-left-right',
  'chevron-up',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'arrow-down-right',
  'arrow-left',
  'arrow-left-right',
  'arrow-right',
  'arrow-up-left',
  'coop',
  'pix',
  'agriculture',
  'building',
  'business',
  'cart',
  'flow',
  'goal',
  'first-aid-kit',
  'patrimony',
  'percent',
  'plane',
  'calendar-cash',
  'calendar-checked',
  'calendar',
  'date-checked',
  'calculator',
  'notebook',
  'phone',
  'smartwatch',
  'smartphone',
  'smartphone-add',
  'dialog-chat',
  'dialog-info',
  'dialog-warning',
  'dialog-question',
  'report-medical',
  'report',
  'document',
  'document-add',
  'document-cash',
  'document-checked',
  'document-error',
  'badge',
  'payment',
  'payment-checkbook',
  'card-machine',
  'card',
  'cards',
  'cash',
  'stacked-cash',
  'checkbook',
  'checkbooks',
  'card-add',
  'cash-coins',
  'cash-out',
  'currency',
  'money',
  'pay',
  'previdence',
  'wallet',
  'savings',
  'operation-card-in',
  'operation-card-out',
  'financial-feedback-success',
  'financial-feedback-error',
  'financial-feedback-waiting',
  'receipt',
  'checkbook-hand',
  'points-adjust',
  'points-earn',
  'points-expire',
  'points-redeem',
  'points-refund',
  'cake',
  'cup',
  'bar-chart',
  'clipboard',
  'pie-chart',
  'presentation',
  'result-chart-positive',
  'result-chart-negative',
  'heart',
  'healthcare',
  'book',
  'binoculars',
  'compass',
  'gift',
  'key',
  'lamp',
  'medal',
  'sofa',
  'target',
  'trophy',
  'printer',
  'assembly',
  'atm',
  'people',
  'collaboration',
  'graduation',
  'relationship',
  'user',
  'user-tie',
  'agency',
  'house',
  'store',
  'data-safety',
  'safe',
  'safe-box',
  'password',
  'bar-code',
  'at',
  'cloud',
  'qr-code',
  'sac',
  'star-services',
  'sync',
  'tools',
  'web',
  'web-channel',
  'alert',
  'checked',
  'feedback-error',
  'feedback-info',
  'feedback-question',
  'feedback-success',
  'satisfaction-dissatisfied',
  'satisfaction-neutral',
  'satisfaction-satisfied',
  'ecology',
  'leaf',
  'solar-power',
  'car',
  'motorcycle',
  'ship',
  'tractor',
  'truck',
  'vehicle',
  'exclamation',
  'circle',
  'circle-filled',
  'switch',
  'download',
  'basket',
  'location',
  'expand',
  'robot',
  'cutlery',
  'footprint',
  'hanger',
  'umbrella',
  'open-finance',
  'open-finance-filled',
  'whatsapp',
  'menu',
  'wifi-error',
  'pix-filled',
  'social-capital',
  'camera-default',
  'camera-update',
  'fashion',
  'folder',
  'fast-food',
  'arrow-down-up',
  'cash-home',
  'clips',
  'heart-filled',
] as const;

const iconFillColor = {
  primary: variables.primary,
  error: variables.error,
  warning: variables.warning,
  danger: variables.danger,
  disabled: variables.stack,
  default: '',
};

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: typeof IconNames[number];
  size?: 8 | 16 | 24 | 32 | 40 | 56 | 64 | 72 | 104;
  appearance?:
  | 'primary'
  | 'default'
  | 'error'
  | 'warning'
  | 'danger'
  | 'disabled';
}

/**
 * @function Icon
 * @returns React.ReactNode
 */
const Icon: React.FC<IconProps> = ({
  name,
  size = 24,
  appearance = 'default',
  ...rest
}): JSX.Element | null => {
  const iconName = pascalCase(`${name}-${size}X${size}`).replace('_', '');

  const ImportedIcon = useMemo(() => lazy(
    // @ts-ignore
    () => import('@sicredi/react-assets').then(module => {
      if (!module[iconName]){
        throw new Error(`O ícone "${iconName}" que você está tentando
         importar não foi encontrado na biblioteca de assets com este nome`);
      }
      return { default: module[iconName] };
    })
  ), []);

  return (
    <Suspense fallback={null}>
      <ImportedIcon
        style={{
          color: iconFillColor[appearance],
        }}
        {...rest}
      />
    </Suspense>
  );
};

export default Icon;
