export const PREVICON = `
  <svg
    class="icon -chevron-left"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    aria-labelledby="chevron-left-title-icon"
    style="color: #3fa110;"
  >
    <title>Ícone de seta para esquerda</title>
    <path
      fill="currentColor"
      d="m7.956 12 9.148-9.146a.5.5 0 0 0-.707-.708l-9.5 9.5a.5.5 0 0 0 0 .708l9.5 9.5a.5.5 0 1 0 .707-.708L7.956 12z"
    >
    </path>
  </svg>
`
export const NEXTICON = `
  <svg
    class="icon -chevron-right"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    aria-labelledby="chevron-right-title-icon"
    style="color: #3fa110;"
  >
    <title>Ícone de seta para direita</title>
    <path
      fill="currentColor"
      d="m16.044 12-9.148 9.146a.5.5 0 0 0 .707.708l9.5-9.5a.5.5 0 0 0 0-.708l-9.5-9.5a.5.5 0 1 0-.707.708L16.044 12z"
    >
    </path>
  </svg>
`