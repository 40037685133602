import styles from '@sicredi/styles/_radio.scss';
import cn from 'clsx';
import * as React from 'react';
import { hash, isEmpty } from '../utils';
import RadioIcon from './Icon';

export interface Data {
  label?: React.ReactNode;
  disabled?: boolean;
  value: string | string[] | number;
}

export interface Schema extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode;
  name: string;
  data: Data;
  value?: string | string[] | number;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

export interface AriaLabelProps extends Schema {
  'aria-label': string;
}

export interface AriaLabelledbybyProps extends Schema {
  'aria-labelledby': string;
}

export type Props = AriaLabelProps | AriaLabelledbybyProps;

const Single = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      data,
      value,
      required,
      disabled,
      readOnly,
      invalid,
      onChange,
      className,
      ...props
    },
    ref
  ) => {
    function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
      e.persist();
      onChange && onChange(e);
    }

    return (
      <div className={cn(styles['sicredi-radio-wrapper'], className)}>
        <div
          data-radio=""
          className={cn(styles['sicredi-radio'], {
            [styles['-invalid']]: invalid,
            [styles['-disabled']]: disabled ? disabled : data.disabled,
            [styles['-readonly']]: readOnly,
          })}
        >
          <input
            id={`${hash(name + data.value)}-radio`}
            ref={ref}
            name={name}
            type="radio"
            role="radio"
            value={data.value}
            disabled={disabled ? disabled : data.disabled}
            tabIndex={
              (disabled ? disabled : data.disabled) || readOnly ? -1 : 0
            }
            onChange={handleChange}
            required={required}
            readOnly={readOnly}
            className={styles['input']}
            defaultChecked={props.defaultChecked}
            data-input=""
            aria-invalid={invalid}
            data-testid={
              props['data-testid'] ? props['data-testid'] : undefined
            }
            aria-required={required ? 'true' : 'false'}
            aria-disabled={
              (disabled ? disabled : data.disabled) ? 'true' : 'false'
            }
            aria-labelledby={props['aria-labelledby']}
            aria-label={props['aria-label']}
            aria-errormessage={`${name}-radio-error`}
          />
          <label
            id={`${hash(name + data.value)}-radio-label`}
            htmlFor={`${hash(name + data.value)}-radio`}
            className={styles['label']}
            data-label=""
          >
            <RadioIcon
              checked={!isEmpty(data.value) || !isEmpty(value)}
            />

            {data.label && (
              <span className={styles['label-text']}>{data.label}</span>
            )}
          </label>
        </div>
      </div>
    );
  }
);

export default Single;
