import * as React from 'react';

import { Props as InputProps } from '../input/Input';
import MaskedInput, {
  Props as MaskedInputProps,
} from '../masked-input/MaskedInput';

export interface Props
  extends Pick<InputProps, Exclude<keyof InputProps, 'onChange'>> {
  onChange?: (value: string) => void;
  config?: MaskedInputProps['config'];
}

export interface CompoundedCurrentInput extends React.FC<Props> {
  parse: (currency: string, config?: MaskedInputProps['config']) => Number;
  format: (
    currency: number | string,
    config?: MaskedInputProps['config']
  ) => string;
}

const CurrentInput: CompoundedCurrentInput = ({
  value,
  onChange,
  config,
  ...props
}) => {
  const inputRef = React.createRef<HTMLInputElement>();
  const [internalValue, setInternalValue] = React.useState('');

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInternalValue(e.target.value);

    onChange && onChange(e.target.value);
  }

  return (
    <MaskedInput
      ref={inputRef}
      mask="CURRENCY"
      value={value !== undefined ? value : internalValue}
      onChange={handleChange}
      preserveChars={[',']}
      config={config}
      {...props}
    />
  );
};

CurrentInput.parse = function(
  currency: string,
  config?: MaskedInputProps['config']
): number {
  return Number(
    MaskedInput.unmask(
      currency,
      config && config['allowNegative'] ? [','].concat('-') : [',']
    ).replace(',', '.')
  );
};

CurrentInput.format = function(
  currency: number | string,
  config?: MaskedInputProps['config']
): string {
  return MaskedInput.mask(currency, 'CURRENCY', config);
};

export default CurrentInput;
