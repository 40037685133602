import * as React from 'react';
import styles from '@sicredi/styles/_accordion.scss';
import cn from 'clsx';

import Item, { Props as ItemProps } from './Item';

export interface Schema
  extends Pick<
    React.HTMLAttributes<HTMLUListElement>,
    Exclude<keyof React.HTMLAttributes<HTMLUListElement>, 'onChange'>
  > {
  children?: React.ReactNode;
  onChange?: (expanded: number[]) => void;
}

export interface AriaLabelProps extends Schema {
  'aria-label': string;
}

export interface AriaLabelledbybyProps extends Schema {
  'aria-labelledby': string;
}

export type Props = AriaLabelProps | AriaLabelledbybyProps;

export interface CompoundedAccordion extends React.FC<Props> {
  Item: React.FC<ItemProps>;
}

const Accordion: CompoundedAccordion = ({
  children,
  onChange,
  className,
  ...props
}) => {
  const contents = React.Children.toArray(children) as React.ReactElement<
    ItemProps
  >[];

  const expanded = contents
    .map(({ props }, i) => (props.expanded ? i : -1))
    .filter(i => i !== -1);

  const [internalExpanded, setInternalExpanded] = React.useState<number[]>(
    expanded
  );

  function enhanceClick(i: number, onClick: ItemProps['onClick']) {
    return () => {
      const nextInternalExpanded =
        internalExpanded.find(item => item === i) === undefined
          ? [...internalExpanded, i]
          : internalExpanded.filter(item => item !== i);

      setInternalExpanded(nextInternalExpanded);

      onClick && onClick(i);

      onChange && onChange(expanded.length ? expanded : nextInternalExpanded);
    };
  }

  return (
    <ul
      role="group"
      className={cn(styles['sicredi-accordion'], className)}
      {...props}
    >
      {contents.map((component, i: number) =>
        React.cloneElement(component, {
          onClick: enhanceClick(i, component.props.onClick),
          expanded: expanded.length
            ? component.props.expanded
            : internalExpanded.find(item => item === i) !== undefined,
        })
      )}
    </ul>
  );
};

Accordion.Item = Item;

export default Accordion;
