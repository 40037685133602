import React, { Fragment, StrictMode } from 'react';
import { hot } from 'react-hot-loader/root';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Banner } from '../../components/Banner';
import { CategoryProvider } from '../../common/Context/Category.Context';
import { ModalProvider } from '../../common/Context/Modal.Context';
import './index.scss';
import { BannerProvider } from '../../common/Context/Banner.Context';

export const App: React.FC = () => {
  const queryClient = new QueryClient();
  return (
    <Fragment>
      <main>
        <QueryClientProvider client={queryClient}>
          <CategoryProvider>
            <ModalProvider>
              <BannerProvider>
                <Banner />
              </BannerProvider>
            </ModalProvider>
          </CategoryProvider>
        </QueryClientProvider>
      </main>
    </Fragment>
  );
};

export default hot(App);
