import * as React from 'react';
import cn from 'clsx';
import styles from '@sicredi/styles/_layout.scss';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
  show?: boolean;
}

const Aside: React.FC<Props> = ({
  show,
  children,
  className,
  ...props
}) => (
  <aside
    className={cn(styles['sicredi-layout-aside'], className)}
    aria-hidden={show ? 'false' : 'true'}
    {...props}
  >
    {children}
  </aside>
);

Aside.defaultProps = {
  show: true,
};

export default Aside;
