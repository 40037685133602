import cn from 'clsx';
import * as React from 'react';
import styles from '@sicredi/styles/_grid.scss';

export type RowSizes =
  | 'xxx-small'
  | 'xx-small'
  | 'x-small'
  | 'small'
  | 'medium'
  | 'large'
  | 'x-large'
  | 'xx-large'
  | 'xxx-large';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  size: RowSizes;
  hideOnXSmallscreen?: boolean;
  hideOnSmallscreen?: boolean;
  hideOnMediumscreen?: boolean;
  hideOnLargescreen?: boolean;
  hideOnXLargescreen?: boolean;
}

const Row: React.FC<Props> = ({
  className,
  children,
  size,
  hideOnXSmallscreen,
  hideOnSmallscreen,
  hideOnMediumscreen,
  hideOnLargescreen,
  hideOnXLargescreen,
  ...props
}) => {
  return (
    <div
      {...props}
      className={cn(styles['sicredi-grid-row'], styles[size], className, [
        hideOnXSmallscreen && styles['hide-xsc'],
        hideOnSmallscreen && styles['hide-sc'],
        hideOnMediumscreen && styles['hide-md'],
        hideOnLargescreen && styles['hide-lg'],
        hideOnXLargescreen && styles['hide-xlg'],
      ])}
      data-testid={
        props['data-testid'] ? props['data-testid'] : 'grid-row-item'
      }
    >
      {children}
    </div>
  );
};

export default Row;
