import React from 'react';
import styles from "@sicredi/styles/_carousel.scss";

export interface Props extends React.AllHTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
  active?: boolean;
  ariaLabelledby?: string;
  as?: any;
}

export default function Item({
  children,
  active,
  as: Component = 'div',
  ariaLabelledby,
  ...props
}: Props) {
  const isImg = Component === 'img';

  return (
    <li
      className={styles['item']}
      role="group"
      aria-hidden={!active}
      aria-labelledby={ariaLabelledby}
    >
      <Component
        {...props}
        className={isImg ? styles['image'] : undefined}
      >
        {children}
      </Component>
    </li>
  );
}
