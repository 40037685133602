import { Subtract } from 'utility-types';
import React, { useReducer } from 'react';
import getDisplayName from 'react-display-name';

import { Icon } from '../../icon';
import { Asset } from '../../asset';
import { Feedback, FeedbackProps } from '../../feedback';

export type JobTemplate =
  | 'WEB_CRM'
  | 'WEB_PUBLIC'
  | 'WEB_PRIVATE'
  | 'WEB_INTERNAL';

enum FeedbackActionType {
  ERROR = 'ERROR',
  SUCCEEDED = 'SUCCEEDED',
  CUSTOM = 'CUSTOM',
  CLEAR = 'CLEAR',
  EXCEPTION = 'EXCEPTION',
}

type FeedbackPropsWithoutIcon = Omit<FeedbackProps, 'icon'>;

type State = Partial<{
  type: keyof typeof FeedbackActionType;
  payload?: FeedbackProps | string;
}>;

export type WithFeedbackProps = {
  setError: (payload: FeedbackPropsWithoutIcon) => void;
  setFeedback: (payload: FeedbackProps) => void;
  setSucceeded: (payload: FeedbackPropsWithoutIcon) => void;
  setException: (payload: string) => void;
  clearFeedback: () => void;
};

const DEFAULT_ICONS = {
  ERROR: {
    WEB_CRM: (
      <Icon name="satisfaction-dissatisfied" aria-hidden="true" size={104} />
    ),
    WEB_INTERNAL: (
      <Asset
        name="action-error"
        data-testid="asset-feedback-error"
        width={300}
      />
    ),
  },
  SUCCEEDED: {
    WEB_CRM: (
      <Icon
        name="feedback-success"
        aria-hidden="true"
        size={104}
        appearance="primary"
      />
    ),
    WEB_INTERNAL: (
      <Asset
        name="action-success"
        data-testid="asset-feedback-success"
        width={300}
      />
    ),
  },
};

function reducer(state: State, action: State) {
  switch (action.type) {
    case FeedbackActionType.ERROR:
    case FeedbackActionType.EXCEPTION:
    case FeedbackActionType.SUCCEEDED:
    case FeedbackActionType.CUSTOM: {
      return {
        ...state,
        type: action.type,
        payload: action.payload,
      };
    }
    case FeedbackActionType.CLEAR: {
      return {};
    }
    /* istanbul ignore next */
    default: {
      return state;
    }
  }
}

export function withFeedback<P extends WithFeedbackProps>(
  Component: React.ComponentType<P>,
  jobTemplateType: JobTemplate = 'WEB_INTERNAL'
) {
  const WithFeedback: React.FunctionComponent<Subtract<
    P,
    WithFeedbackProps
  >> = props => {
    const [state, dispatch] = useReducer(reducer, {});

    if (state?.type === FeedbackActionType.EXCEPTION) {
      throw new Error((state?.payload as string) ?? '');
    }

    const succeededIcon =
      DEFAULT_ICONS?.SUCCEEDED?.[jobTemplateType] ??
      DEFAULT_ICONS.SUCCEEDED.WEB_INTERNAL;
    const errorIcon =
      DEFAULT_ICONS?.ERROR?.[jobTemplateType] ?? DEFAULT_ICONS.ERROR.WEB_CRM;

    return state?.type ? (
      <Feedback {...(state.payload as FeedbackProps)} />
    ) : (
      <Component
        {...(props as P)}
        setError={payload =>
          dispatch({
            type: FeedbackActionType.ERROR,
            payload: { ...payload, icon: errorIcon },
          })
        }
        setFeedback={payload =>
          dispatch({ type: FeedbackActionType.CUSTOM, payload })
        }
        setSucceeded={payload =>
          dispatch({
            type: FeedbackActionType.SUCCEEDED,
            payload: { ...payload, icon: succeededIcon },
          })
        }
        setException={payload =>
          dispatch({ type: FeedbackActionType.EXCEPTION, payload })
        }
        clearFeedback={() => dispatch({ type: FeedbackActionType.CLEAR })}
      />
    );
  };

  WithFeedback.displayName = `${getDisplayName(WithFeedback)}(${getDisplayName(
    Component
  )})`;

  return WithFeedback;
}
