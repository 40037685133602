import React, { Fragment } from 'react';
import { Props as MaskedInputProps } from '../masked-input/MaskedInput';
import Multiple from './Multiple';
import Single from './Single';

export type InputProps = Pick<
  MaskedInputProps,
  Exclude<keyof MaskedInputProps, 'mask' | 'label'>
> & {
  mask?: MaskedInputProps['mask'];
};

export interface Item {
  label: string;
  value: InputProps['value'];
}
export interface Props
  extends Pick<
    InputProps,
    Exclude<keyof InputProps, 'mask' | 'onChange' | 'value'>
  > {
  data: Item[];
  label: MaskedInputProps['label'];
  loading?: boolean;
  multiple?: boolean;
  multipleAlwaysCount?: boolean;
  maxRenderedItems?: number;
  value?: InputProps['value'] | number[];
  onChange?: (value: any) => void;
  renderItem?: (children: React.ReactNode) => React.ReactNode;
  inputProps?: InputProps & { ref?: React.Ref<HTMLInputElement> };
}

const Select: React.FC<Props> = props => {
  return (
    <Fragment>
      {props.multiple ? <Multiple {...props} /> : <Single {...props} />}
    </Fragment>
  );
};

export default Select;
