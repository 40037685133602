import isMobileThirdParty from 'ismobilejs';

export function isMobile() {
  return isMobileThirdParty['isMobile']().any;
}

const seen = new WeakSet();

export function hash(value: any): string {
  return window.btoa(
    unescape(
      encodeURIComponent(
        JSON.stringify(value, (_, value) => {
          if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) return;
            seen.add(value);
          }
          return value;
        })
      )
    )
  );
}

export function stripTags(str: string): string {
  return str.replace(/<\/?[^>]+(>|$)/g, '');
}

// baseado https://stackoverflow.com/questions/7208161/focus-next-element-in-tab-index#answer-47840891
export function getFocusableElement({
  direction,
  activeElement = document.activeElement!,
}: {
  direction: 'next' | 'previous';
  activeElement?: HTMLElement | Element;
}) {
  const queryString = [
    'a:not([disabled]):not([tabindex="-1"])',
    'button:not([disabled]):not([tabindex="-1"])',
    'input:not([disabled]):not([tabindex="-1"])',
    'select:not([disabled]):not([tabindex="-1"])',
    '[tabindex]:not([disabled]):not([tabindex="-1"])',
    /* add custom queries here */
  ].join(',');

  const queryResult = Array.prototype.filter.call(
    document.querySelectorAll(queryString),
    (elem: any) => {
      /*check for visibility while always include the current activeElement*/
      return (
        elem.offsetWidth > 0 || elem.offsetHeight > 0 || elem === activeElement
      );
    }
  );

  const indexedList = queryResult
    .slice()
    .filter((elem: any) => {
      /* filter out all indexes not greater than 0 */
      return elem.tabIndex === 0 || elem.tabIndex === -1 ? false : true;
    })
    .sort((a: any, b: any) => {
      /* sort the array by index from smallest to largest */
      return a.tabIndex !== 0 && b.tabIndex !== 0
        ? a.tabIndex < b.tabIndex
          ? -1
          : b.tabIndex < a.tabIndex
          ? 1
          : 0
        : a.tabIndex !== 0
        ? -1
        : b.tabIndex !== 0
        ? 1
        : 0;
    });

  const focusable = [].concat(
    indexedList,
    queryResult.filter((elem: any) => {
      /* filter out all indexes above 0 */
      return elem.tabIndex === 0 || elem.tabIndex === -1 ? true : false;
    })
  );

  return direction === 'previous'
    ? focusable[focusable.indexOf(activeElement as never) - 1] ||
        focusable[focusable.length - 1]
    : focusable[focusable.indexOf(activeElement as never) + 1] || focusable[0];
}

export function timer(callback: () => void, timeout?: number) {
  let timerId: number;
  let start: number;
  let remaining = timeout || 0;

  function clear(): void {
    window.clearTimeout(timerId);
  }

  function pause(): void {
    clear();
    remaining -= Date.now() - start;
  }

  function play(): void {
    start = Date.now();
    window.clearTimeout(timerId);
    timerId = window.setTimeout(callback, remaining);
  }

  play();

  return {
    clear,
    pause,
    play,
  };
}

export function normalizeNumber(value: string): string {
  if (value.length > 1 && value.startsWith('0') && !value.includes(',')) {
    return parseFloat(value).toString();
  }
  return value;
}

export function createFile(name: string, size: number, type: string) {
  const file = new File([], name, { type });

  Object.defineProperty(file, 'size', {
    get() {
      return size;
    },
  });

  return file;
}

/**
 * @function isNil
 * @description Checks if value is null or undefined.
 * @param value
 * @returns {Boolean}
 */
export function isNil(value: any): boolean {
  return value === undefined || value === null;
}

/**
 * isEmpty - Check if the given array, object, boolean or string is empty
 * @param obj
 */
export function isEmpty(
  obj:
    | any[]
    | { [key: string]: any }
    | string
    | number
    | boolean
    | undefined
    | null
): boolean {
  if (!obj) {
    return true;
  }

  if (isNil(obj)) {
    return true;
  }

  if (Array.isArray(obj) && obj.length === 0) {
    return true;
  }
  // because Object.entries(new Date()).length === 0;
  // we have to do some additional check
  return Object.entries(obj).length === 0 && obj.constructor === Object;
}
