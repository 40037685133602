import * as React from 'react';
import cn from 'clsx';
import styles from '@sicredi/styles/_title.scss';

export interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  children?: React.ReactNode;
  as?: any; // TODO: add correct typing
  size?: 'medium' | 'large' | 'x-large' | 'xx-large' | 'xxx-large';
  weight?: 'x-light' | 'light' | 'normal' | 'semi-bold' | 'bold';
  appearance?: 'primary' | 'secondary';
  [key: string]: any;
}

const Title: React.FC<Props> = ({
  as: Component,
  size,
  className,
  appearance,
  weight,
  ...props
}) => (
  <Component
    className={cn(styles['sicredi-title'], className, {
      [styles['-primary']]: appearance === 'primary',
      [styles['-secondary']]: appearance === 'secondary',
      [styles['-medium']]: size === 'medium',
      [styles['-large']]: size === 'large',
      [styles['-x-large']]: size === 'x-large',
      [styles['-xx-large']]: size === 'xx-large',
      [styles['-xxx-large']]: size === 'xxx-large',
      [styles['-x-light']]: weight === 'x-light',
      [styles['-light']]: weight === 'light',
      [styles['-normal']]: weight === 'normal',
      [styles['-semi-bold']]: weight === 'semi-bold',
      [styles['-bold']]: weight === 'bold',
    })}
    {...props}
  />
);

Title.defaultProps = {
  as: 'h1',
};

export default Title;
