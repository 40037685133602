import styles from '@sicredi/styles/_input.scss';
import cn from 'clsx';
import * as React from 'react';
import FieldSubscript from '../shared/field-subscript/FieldSubscript';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  type?: 'tel' | 'text' | 'date' | 'number' | 'search' | 'password';
  label: React.ReactNode;
  hintMessage?: React.ReactNode;
  errorMessage?: React.ReactNode;
  showProgress?: Boolean;
}

const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      icon,
      name,
      type,
      value,
      label,
      onBlur,
      onChange,
      required,
      disabled,
      readOnly,
      maxLength,
      showProgress,
      className,
      hintMessage,
      errorMessage,
      placeholder = '',
      ...props
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = React.useState('');

    function handleBlur(e: React.FocusEvent<HTMLInputElement>): void {
      // adjust firefox behavior... when input's type is number, firefox doesn't dispatch change event but
      // fill the field with new value, to fix that, we clear input when value is not a number
      e.target.placeholder = '';
      if (type === 'number' && !e.target.validity.valid) {
        e.target.value = '';
        setInternalValue('');
      }

      onBlur && onBlur(e);
    }

    function onFocus(e: React.FocusEvent<HTMLInputElement>) {
      e.target.placeholder = placeholder;
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
      // adjust input type number behavior... when input type is number, maxlength doesn't work, to fix that,
      // we trunc any value on input change to maxlength declared in input
      if (maxLength && e.target.value && maxLength < e.target.value.length) {
        e.target.value = String(e.target.value).substring(maxLength, 0);
      }

      setInternalValue(e.target.value);

      onChange && onChange(e);
    }

    function renderDataTestingId(id: string) {
      return `${props['data-testid']}-${id}`;
    }

    function renderAriaAttribute(property: boolean | undefined) {
      if (property) {
        return 'true';
      }
      return 'false';
    }

    return (
      <div
        className={cn(className, styles['sicredi-input'], styles[`-${type}`], {
          [styles['-invalid']]: !!errorMessage && !disabled,
          [styles['-disabled']]: disabled,
          [styles['-readonly']]: readOnly,
        })}
      >
        {icon &&
          React.cloneElement(icon as React.ReactElement, {
            className: cn(styles['icon'], icon['props']['className']),
            'data-icon': '',
            'data-testid': renderDataTestingId('icon'),
          })}

        <input
          id={`${name}-input`}
          ref={ref}
          name={name}
          type={type}
          value={value !== undefined ? value : internalValue}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={onFocus}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          minLength={maxLength ? 0 : undefined}
          maxLength={maxLength}
          className={cn(styles['input'], {
            [styles['-filled']]:
              !!(value !== undefined ? value : internalValue) ||
              (type === 'number' && value === 0),
          })}
          tabIndex={disabled || readOnly ? -1 : 0}
          data-input=""
          autoComplete="off"
          aria-invalid={errorMessage && !disabled ? 'true' : 'false'}
          aria-readonly={renderAriaAttribute(readOnly)}
          aria-disabled={renderAriaAttribute(disabled)}
          aria-required={renderAriaAttribute(required)}
          aria-labelledby={`${name}-input-label`}
          aria-errormessage={`${name}-input-error`}
          {...props}
        />
        <label
          id={`${name}-input-label`}
          htmlFor={`${name}-input`}
          className={styles['label']}
          data-label=""
        >
          {label} {!required && !disabled && '(Opcional)'}
        </label>

        <FieldSubscript>
          <FieldSubscript.Group>
            <FieldSubscript.Message
              id={`${name}-input-error`}
              show={!!errorMessage && !disabled}
              invalid={true}
              aria-live="polite"
              role="alert"
              data-testid={renderDataTestingId('error')}
              data-message=""
            >
              {errorMessage}
            </FieldSubscript.Message>
            <FieldSubscript.Message
              id={`${name}-input-hint`}
              show={!!hintMessage && !errorMessage}
              data-testid={renderDataTestingId('hint')}
              data-message=""
            >
              {hintMessage}
            </FieldSubscript.Message>
          </FieldSubscript.Group>

          {maxLength && showProgress && (
            <FieldSubscript.Progress
              max={Number(maxLength)}
              value={String(value !== undefined ? value : internalValue).length}
              data-testid={renderDataTestingId('progress')}
            />
          )}
        </FieldSubscript>
      </div>
    );
  }
);

Input.defaultProps = {
  type: 'text',
};

export default Input;
