import { DomainPT } from '../Interfaces/DomainPayload.Interface';
import { APPLICATION } from '../../constants';
import axios from 'axios';

let domainCache: DomainPT | null = null;

export async function getDomain(domainId: string) {
  if (domainCache === null) {
    const { data } = await axios.get<DomainPT>(
      `${APPLICATION.BFF_URL}dominios/${domainId}`,
    );
    domainCache = data;
  }

  return domainCache;
}
