import React from 'react';
import cn from 'clsx';
import styles from '@sicredi/styles/_breadcrumb.scss';

import Item, { Props as ItemProps } from './Item';

export interface Schema extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
}

export interface AriaLabelProps extends Schema {
  'aria-label'?: string;
}

export interface AriaLabelledbybyProps extends Schema {
  'aria-labelledby': string;
}

export type Props = AriaLabelProps | AriaLabelledbybyProps;

export interface CompoundedBreadcrumb extends React.FC<Props> {
  Item: React.FC<ItemProps>;
}

const Breadcrumb: CompoundedBreadcrumb = ({
  children,
  className,
  ...props
}) => (
  <nav
    className={cn(styles['sicredi-breadcrumb'], className)}
    {...props}
    aria-label={props['aria-labelledby'] ? undefined : props['aria-label']}
  >
    <ol data-items="" className={styles['items']}>
      {React.Children.map(
        children,
        (component: React.ReactElement<ItemProps>, i) =>
          React.cloneElement(component, {
            active: i === React.Children.count(children) - 1,
          })
      )}
    </ol>
  </nav>
);

Breadcrumb.defaultProps = {
  'aria-label': 'Trilha de navegação',
};

Breadcrumb.Item = Item;

export default Breadcrumb;
