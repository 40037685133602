import * as React from 'react';
import { Icon } from '..';
import styles from '@sicredi/styles/_accordion.scss';

import { hash } from '../utils';

export interface Props {
  children?: React.ReactNode;
  label: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement> | number) => void;
  expanded?: boolean;
}

const Item: React.FC<Props> = ({
  label,
  onClick,
  children,
  expanded,
  ...props
}) => (
  <li data-item="">
    <h3 role="heading" aria-level={3} data-heading="">
      <button
        type="button"
        onClick={onClick}
        tabIndex={0}
        data-button=""
        aria-controls={`${hash(label)}-accordion-content`}
        aria-expanded={expanded ? 'true' : 'false'}
        {...props}
      >
        <div className={styles['label-heading']}>
          {label}
        </div>
        
        <Icon
          name="chevron-down"
          className={styles['icon']}
          data-icon=""
          data-testid={
            props['data-testid'] ? `${props['data-testid']}-icon` : undefined
          }
        />
      </button>
    </h3>
    <div
      id={`${hash(label)}-accordion-content`}
      className={styles['content']}
      aria-hidden={expanded ? 'false' : 'true'}
      data-testid={
        props['data-testid'] ? `${props['data-testid']}-content` : undefined
      }
      data-content=""
    >
      {children}
    </div>
  </li>
);

export default Item;
