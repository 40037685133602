import React, { isValidElement } from 'react';

import styles from '@sicredi/styles/_tag.scss';

export type Props = {
  children?: React.ReactNode;
  icon?: React.ReactNode;
};

const Tag: React.FC<Props> = ({ icon, children, ...props }) => {
  return (
    <div className={styles['sicredi-tag']} {...props}>
      {isValidElement(icon) && (
        <span
          aria-hidden="true"
          className={styles['icon']}
          data-testid={props['data-testid'] && `${props['data-testid']}-icon`}
        >
          {icon}
        </span>
      )}

      {children}
    </div>
  );
};

export default Tag;
