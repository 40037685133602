import React, { createContext, useContext } from "react";

import { JobTemplate } from "../../hocs/withFeedback";
import { withFeedback, WithFeedbackProps } from "../..";

type FeedbackProviderProps = {
  jobTemplateType?: JobTemplate;
  children: React.ReactNode;
}

const FeedbackContext = createContext<WithFeedbackProps>({} as WithFeedbackProps);

export const FeedbackProvider: React.FC<FeedbackProviderProps> = ({ jobTemplateType, children }) => {
  const Component = withFeedback(({ children: componentChildren, ...feedback }) => (
    <FeedbackContext.Provider value={feedback}>
      {componentChildren}
    </FeedbackContext.Provider>
  ), jobTemplateType);

  return <Component children={children} />
};

export function useFeedback() {
  const context = useContext(FeedbackContext);

  if (Object.keys(context).length === 0) {
    throw new Error("useFeedback must be used within a FeedbackProvider");
  }

  return context;
}