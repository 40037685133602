import * as React from 'react';
import cn from 'clsx';
import styles from '@sicredi/styles/_tracking.scss';

import { STATUS } from './constants';
import { Icon, IconProps } from '../icon';

export type Status = 'error' | 'processing' | 'pending' | 'finished';

export interface Props {
  children?: React.ReactNode;
  title: React.ReactNode;
  status: Status;
  className?: string;
  nextStatus?: Status;
}

const ICONS = {
  error: (props: Omit<IconProps, 'name'>) => <Icon {...props} name="feedback-error" />,
  pending: (props: Omit<IconProps, 'name'>) => (
    <Icon {...props} name="circle" />
  ),
  finished: (props: Omit<IconProps, 'name'>) => (
    <Icon {...props} name="feedback-success" />
  ),
  processing: (props: Omit<IconProps, 'name'>) => (
    <Icon {...props} name="circle-filled" />
  ),
};

const Item: React.FC<Props> = ({
  title,
  status,
  children,
  className,
  nextStatus,
  ...props
}) => {
  const TrackingIcon = ICONS[status];

  return (
    <li
      className={cn(
        styles['item'],
        styles[`-${status}`],
        styles[
          `-next${String(nextStatus)
            .charAt(0)
            .toUpperCase() + String(nextStatus).slice(1)}`
        ],
        className
      )}
      aria-current={status === STATUS.PROCESSING ? 'step' : undefined}
      {...props}
    >
      <TrackingIcon className={styles['icon']} aria-hidden="true" />
      <h3 className={styles['title']}>{title}</h3>
      {children && <div className={styles['content']}>{children}</div>}
    </li>
  );
};

export default Item;
