import { Domain } from '../common/Interfaces/Domain.Interface';
import Cookies from 'js-cookie';
import {
  saveDataInTheLocalStorage,
  updatedDomain,
} from '../common/BrowserDataManipulation/CreateDataInTheBrowser/createDataInTheBrowser';
import { getConsent } from '../common/Requests/GetConsent.Request';

export const getConsentService = async (
  domain: Domain | null,
  userId: string,
  domain_: string,
  consentId?: string,
) => {
  const data = await getConsent(userId, domain_);

  if (!data._embedded) {
    Cookies.remove('USERCUUID');
    return;
  }

  const consentData =
    data._embedded.consentimentoResources.find((item) => {
      return item.uuid === consentId;
    }) ?? data._embedded.consentimentoResources[0];

  saveDataInTheLocalStorage(domain, [], consentData);
  updatedDomain(consentData.dominioAtualizado);

  return consentData;
};
