import React, { isValidElement } from 'react';
import { Button, Layout, Meta, Paragraph, Spacing, Title } from '..';
import styles from '@sicredi/styles/_feedback.scss';

export type Props = {
  title: string;
  icon: React.ReactNode;
  children?: React.ReactNode;
  primaryButton?: React.ComponentProps<typeof Button>;
  secondaryButton?: React.ComponentProps<typeof Button>;
};

const Feedback: React.FC<Props> = ({
  icon,
  title,
  children,
  primaryButton,
  secondaryButton,
}) => {
  return (
    <Layout className={styles['react-feedback-layout-wrapper']}>
      <Meta title={title} />

      <Layout.Container className={styles['react-feedback-layout-container']}>
        <Layout.Content
          data-testid="feedback-content"
          className={styles['react-feedback-layout-content']}
        >
          <div className={styles['react-feedback-children-content']}>
            <span data-testid="feedback-icon">{icon}</span>
            <Spacing appearance="small" />

            <Title 
              data-testid="feedback-title"
              className={styles['react-feedback-title']}
            >
              {title}
            </Title>
            <Spacing appearance="small" />

            <div 
              data-testid="feedback-children" 
              className={styles['react-feedback-children-component']}
            >
              {isValidElement(children) ? (
                children
              ) : (
                <Paragraph className={styles['react-feedback-children']}>
                  {children}
                </Paragraph>
              )}
            </div>
          </div>

          {(primaryButton || secondaryButton) && (
            <>
              <Spacing appearance="large" />
              <Layout.Wrapper>
                {secondaryButton && (
                  <Button
                    ghost
                    appearance="primary"
                    data-testid="feedback-secondary-button"
                    size='large'
                    {...secondaryButton}
                  />
                )}
                {primaryButton && (
                  <Button
                    appearance="primary"
                    data-testid="feedback-primary-button"
                    size='large'
                    {...primaryButton}
                  />
                )}
              </Layout.Wrapper>
            </>
          )}
        </Layout.Content>
      </Layout.Container>
    </Layout>
  );
};

export default Feedback;
