import * as React from 'react';
import cn from 'clsx';
import styles from '@sicredi/styles/_spacing.scss';

export interface Schema extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

export interface AppearanceProps extends Schema {
  appearance?:
    | 'xxx-small'
    | 'xx-small'
    | 'x-small'
    | 'small'
    | 'medium'
    | 'large'
    | 'x-large'
    | 'xx-large'
    | 'xxx-large';
}

export interface HeightProps extends Schema {
  height?: string;
}

export type Props = AppearanceProps | HeightProps;

const Spacing: React.FC<Props> = ({ className, ...props }) => (
  <div
    style={{ height: props['height'] || undefined }}
    className={cn(styles['sicredi-spacing'], className, {
      [styles['-xxx-small']]: props['appearance'] === 'xxx-small',
      [styles['-xx-small']]: props['appearance'] === 'xx-small',
      [styles['-x-small']]: props['appearance'] === 'x-small',
      [styles['-small']]: props['appearance'] === 'small',
      [styles['-medium']]: props['appearance'] === 'medium',
      [styles['-large']]: props['appearance'] === 'large',
      [styles['-x-large']]: props['appearance'] === 'x-large',
      [styles['-xx-large']]: props['appearance'] === 'xx-large',
      [styles['-xxx-large']]: props['appearance'] === 'xxx-large',
    })}
    {...props}
  />
);

export default Spacing;
