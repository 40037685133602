import * as React from 'react';
import cn from 'clsx';
import styles from '@sicredi/styles/_box.scss';
import { Spacing } from '../spacing';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
  title?: string;
  icon?: React.ReactNode;
  noContentMessage?: string;
  caption?: string;
  appearence?: 'transparent' | 'secondary';
}

const Box: React.FC<Props> = ({
  children,
  title,
  noContentMessage,
  caption,
  className,
  icon,
  appearence = 'transparent',
  ...props
}) => {
  const hasContent = !!title || !!children;

  return (
    <div
      role="box"
      className={cn(
        styles['sicredi-box'],
        { [styles['secondary']]: appearence === 'secondary' },
        { [styles['-center']]: !hasContent },
        className
      )}
      {...props}
    >
      {title && icon && (
        <>
          <span className={cn(styles['icon'])}>{icon}</span>
          <Spacing appearance="small"></Spacing>
        </>
      )}

      {title && caption && (
        <span className={cn(styles['caption'])}>{caption}</span>
      )}

      {title && <h2 className={cn(styles['title'])}>{title}</h2>}
      {!hasContent && !!noContentMessage && (
        <p className={cn(styles['no-content-message'])}>{noContentMessage}</p>
      )}

      {children}
    </div>
  );
};

export default Box;
