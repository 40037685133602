import styles from '@sicredi/styles/_avatar.scss';
import cn from 'clsx';
import React, { useMemo } from 'react';
import { Icon, IconProps } from '..';

type Props = {
  size?: 'x-small' | 'small' | 'medium' | 'large' | 'x-large';
  border?: boolean;
  src?: string;
  appearance?: 'avatar' | 'thumbnail';
} & React.HTMLAttributes<HTMLElement>;

const Avatar: React.FC<Props> = ({
  src,
  size = 'medium',
  border = true,
  appearance = 'avatar',
  ...props
}: Props) => {
  const classAvatar = cn(
    {
      [styles['sicredi-avatar']]: true,
      [styles['thumbnail']]: appearance === 'thumbnail',
      [styles['-x-small']]: size === 'x-small',
      [styles['-small']]: size === 'small',
      [styles['-medium']]: size === 'medium',
      [styles['-large']]: size === 'large',
      [styles['-x-large']]: size === 'x-large',
      [styles['-border']]: border,
    },
    props.className
  );

  const iconName = {
    avatar: 'user',
    thumbnail: 'gallery',
  };

  const iconSize = useMemo(() => {
    const baseSizes = {
      'x-small': 16,
      small: 24,
    };

    if (appearance === 'avatar') {
      return {
        ...baseSizes,
        medium: 32,
        large: 40,
        'x-large': 56,
      };
    }

    return {
      ...baseSizes,
      medium: 40,
      large: 56,
      'x-large': 72,
    };
  }, [appearance]);

  return src ? (
    <img
      role="avatar"
      aria-label={props['aria-label']}
      className={classAvatar}
      src={src}
      data-testid={`${props['data-testid']}-img`}
    />
  ) : (
    <div
      className={classAvatar}
      role="avatar"
      aria-label={props['aria-label']}
      data-testid={props['data-testid']}
    >
      <Icon
        name={iconName[appearance] as IconProps['name']}
        size={iconSize[size] as IconProps['size']}
        className={styles['icon']}
        data-testid={`${props['data-testid']}-icon`}
        style={{
          color: appearance === 'avatar' ? 'inherit' : '#CDD3CD',
        }}
      />
    </div>
  );
};

export default Avatar;
