import * as React from 'react';
import cn from 'clsx';
import styles from '@sicredi/styles/_loader.scss';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  show: boolean;
  className?: string;
  fullScreen?: boolean;
  text?: string;
}

const Loader: React.FC<Props> = ({
  show,
  className,
  fullScreen,
  text,
  ...props
}) => (
  <div
    aria-busy={show ? 'true' : 'false'}
    aria-live="polite"
    className={cn(styles['sicredi-loader'], className, {
      [styles['-show']]: show,
      [styles['-full-screen']]: fullScreen,
    })}
    aria-label="Carregando..."
    aria-hidden={show ? 'false' : 'true'}
    {...props}
  >
    <div className={cn(styles['dots'])}>
      <div className={cn(styles['dot'])}></div>
      <div className={cn(styles['dot'])}></div>
      <div className={cn(styles['dot'])}></div>
    </div>
    {show && text && (
        <p
          className={styles['message']}
        >
          {text}
        </p>
      )}
  </div>
);

export default Loader;
