import * as React from 'react';
import cn from 'clsx';
import ReactModal, { Props as ReactModalProps } from 'react-modal';

import styles from '@sicredi/styles/_modal.scss';
import variables from '@sicredi/styles/_variables.scss';

import Title from '../title/Title';
import Button, { Props as SicrediButtonProps } from '../button/Button';
import { Icon } from '../icon';

export interface Props
  extends Pick<ReactModalProps, Exclude<keyof ReactModalProps, 'isOpen'>> {
  children?: React.ReactNode;
  show: boolean;
  size?: 'small' | 'large';
  title: React.ReactNode;
  onClose?: () => void;
  primaryButton?: SicrediButtonProps;
  secondaryButton?: SicrediButtonProps;
  modalClassName?: string;
}

if (document && document.getElementById('root')) {
  ReactModal.setAppElement('#root');
}

const Modal: React.FC<Props> = ({
  show,
  size,
  title,
  onClose,
  children,
  className,
  primaryButton,
  secondaryButton,
  modalClassName = 'sicredi-modal-container',
  ...props
}) => {
  const [contentRef, setContentRef] = React.useState<HTMLDivElement>();

  React.useEffect(() => {
    if (!contentRef) return;

    setTimeout(() => {
      contentRef.focus();
    }, parseFloat(variables.animationTiming) * 1000);
  }, [contentRef]);

  return (
    <ReactModal
      isOpen={show}
      className={cn(styles['modal'], modalClassName)}
      contentRef={setContentRef}
      onRequestClose={onClose}
      closeTimeoutMS={parseFloat(variables.animationTiming) * 1000}
      overlayClassName={{
        base: cn(styles['sicredi-modal'], className, {
          [styles['-small']]: size === 'small',
          [styles['-large']]: size === 'large',
        }),
        afterOpen: styles['-show'],
        beforeClose: styles['-hide'],
      }}
      bodyOpenClassName={styles['sicredi-modal-show']}
      {...props}
    >
      <div className={styles['header']}>
        <Title as="h2" className={cn(styles['title'], className)}>
          {title}
        </Title>
        <button
          type="button"
          onClick={onClose}
          className={styles['close']}
          aria-label="Fechar"
          data-close=""
        >
          <Icon
            name="close"
            aria-hidden="true"
            data-icon=""
            className={styles['icon']}
          />
        </button>
      </div>

      <div className={styles['body']}>{children}</div>

      {(primaryButton || secondaryButton) && (
        <div className={styles['footer']}>
          {secondaryButton && (
            <Button
              ghost={true}
              onClick={onClose}
              appearance="primary"
              {...secondaryButton}
            />
          )}
          {primaryButton && <Button appearance="primary" {...primaryButton} />}
        </div>
      )}
    </ReactModal>
  );
};

Modal.defaultProps = {
  size: 'large',
};

export default Modal;
