import styles from '@sicredi/styles/_multistep.scss';
import cn from 'clsx';
import React from 'react';

import { Ellipsis } from '../shared/ellipsis';

export type MultistepProps = {
  children?: React.ReactNode;
  previousStep: () => void;
  nextStep: () => void;
  goTo: (step: number) => void;
  goToStepName: (step: string) => void;
  currentStep: number;
  stepsLength: number;
};

export interface Props {
  children?: React.ReactNode;
  render?: (props: MultistepProps) => React.ReactNode;
  label: React.ReactNode;
  name: string;
  current?: boolean;
  icon?: React.ReactElement;
  className?: string;
}

const Item: React.FC<Props> = ({
  label,
  current,
  icon,
  className
}) => (
  <li className={cn(styles['step'], className)} aria-current={current ? "step" : undefined}>
    <h3 className={styles['title']}>
      <Ellipsis>
        {label}
      </Ellipsis>
    </h3>
    { icon }
  </li>
);

export default Item ;
