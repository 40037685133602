import Button, { Props as SicrediButtonProps } from '../button/Button';
import { Instance, Placement, createPopper } from '@popperjs/core';
import { Paragraph, Spacing, Title } from '..';
import React, { useEffect, useMemo } from 'react';

import { Icon } from '../icon';
import cn from 'clsx';
import styles from '@sicredi/styles/_popover.scss';
import { v4 as uuidv4 } from 'uuid';

export interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  children?: React.ReactNode;
  placement: 'top' | 'bottom' | 'left' | 'right';
  title: string;
  icon?: React.ReactElement;
  caption?: string;
  content?: React.ReactNode;
  button?: SicrediButtonProps;
}

const Popover: React.FC<Props> = ({
  placement,
  title,
  icon,
  caption,
  content,
  button,
  ...props
}) => {
  const [hovered, setHover] = React.useState(false);
  const [popperInstance, setInstance] = React.useState({} as Instance);
  const [innerPlacement, setPlacement] = React.useState<Placement>(placement);

  const popoverRef = React.useRef<HTMLSpanElement>(null);
  const timesRef = React.useRef<HTMLSpanElement>(null);
  const wrapperRef = React.useRef<HTMLSpanElement>(null);

  const id = useMemo(() => uuidv4(), [title]);

  useEffect(() => {
    if (!wrapperRef.current || !popoverRef.current || !timesRef.current) return;

    const popper = createPopper(wrapperRef.current, popoverRef.current, {
      placement: innerPlacement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    });

    setInstance(popper);

    return () => {
      popper.destroy();
    };
  }, []);

  async function handleHover(isVisible: boolean) {
    setHover(isVisible);
    if (popperInstance && popperInstance.update) {
      const state = await popperInstance.update();
      setPlacement(state && state.placement ? state.placement : placement);
    }
  }

  window.document.addEventListener(
    'click',
    () => {
      handleHover(false);
    },
    true
  );

  wrapperRef.current &&
    wrapperRef.current.addEventListener('click', event => {
      event.stopImmediatePropagation();
      handleHover(true);
    });

  popoverRef.current &&
    popoverRef.current.addEventListener('click', () => {
      handleHover(true);
    });

  timesRef.current &&
    timesRef.current.addEventListener('click', event => {
      event.stopImmediatePropagation();
      handleHover(false);
    });

  function onClickButton(event: React.MouseEvent<HTMLButtonElement>) {
    if (button && button.onClick) {
      button.onClick(event);
    }
    handleHover(false);
  }

  return (
    <span
      className={cn(styles['sicredi-popover'], props.className)}
      aria-describedby={id}
      {...props}
    >
      <span ref={wrapperRef} data-testid={`${props['data-testid']}-wrapper`}>
        {props.children}
      </span>
      <span
        ref={popoverRef}
        role="popover"
        aria-hidden={!hovered}
        data-testid={`${props['data-testid']}-popover`}
      >
        <span
          className={styles['arrow']}
          data-popper-arrow
          data-testid={`${props['data-testid']}-arrow`}
        ></span>
        <span
          className={styles['times']}
          ref={timesRef}
          data-testid={`${props['data-testid']}-times`}
        >
          <Icon name="close" data-testid={`${props['data-testid']}-icon`}/>
        </span>
        {icon && (
          <>
            {icon}
            <Spacing appearance="small" />
          </>
        )}
        {title && (
          <Title as="h4" data-testid={`${props['data-testid']}-title`}>
            {title}
          </Title>
        )}
        {caption && (
          <>
            <Spacing appearance="small" />
            <Paragraph
              size="small"
              weight="normal"
              data-testid={`${props['data-testid']}-caption`}
            >
              {caption}
            </Paragraph>
          </>
        )}
        {content && (
          <>
            <Spacing appearance="small" />
            {content}
          </>
        )}
        {button && (
          <>
            <Spacing appearance="small" />
            <Button {...button} onClick={onClickButton} data-testid={`${props['data-testid']}-button`}>
              {button.children}
              {button.icon}
            </Button>
          </>
        )}
      </span>
    </span>
  );
};

Popover.defaultProps = {
  placement: 'bottom',
};

export default Popover;
