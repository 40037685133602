import { FC } from 'react';
import { Button, Modal, Paragraph, Title } from '@sicredi/react';
import './index.scss';
import { CookiesSwitch } from '../CookiesSwitch';
import { useCategory } from '../../common/Context/Category.Context';
import { useModal } from '../../common/Context/Modal.Context';
import {
  BANNER_HIDDEN_CLASS,
  useBanner,
} from '../../common/Context/Banner.Context';
import { Consent_Action } from '../../common/Enums/Browsers.Enum';
import { saveDataInTheLocalStorage } from '../../common/BrowserDataManipulation/CreateDataInTheBrowser/createDataInTheBrowser';

export const ManagementModal: FC = () => {
  const { createUserConsent, setAllCategories, domain, categories } =
    useCategory();
  const { isModalVisible, setIsModalVisible } = useModal();
  const { setIsBannerVisible } = useBanner();

  return (
    <Modal
      title={
        <span className="img-content">
          <img
            src="https://web-static-stage.sicredi.io/lgpd-consentimentos-cookies@0.0.1-rc7/static/media/LogoSicredi.04e4b7db.svg"
            alt="Sicredi"
            className="sicredi-logo-modal"
          />
        </span>
      }
      id="cookies-management-modal"
      data-testid="cookies-management"
      show={isModalVisible}
      onClose={() => setIsModalVisible(false)}
      className="sicredi-modal-container"
      parentSelector={() => document.querySelector('#banner-container')}
    >
      <div className="management-modal" data-testid="cookies-management-modal">
        <Title size="xx-large" className="cookies-title">
          Gerenciamento de Permissão de Cookies
        </Title>
        <div className="cookies-container">
          <Paragraph
            data-testid="management-modal-paragraph"
            className="management-modal-paragraph"
          >
            Quando você visita qualquer site, ele pode armazenar ou recuperar
            informações no seu navegador, principalmente na forma de cookies.
            Essas informações podem ser sobre você, suas preferências ou seu
            dispositivo e são usadas principalmente para fazer o site funcionar
            como esperado. As informações geralmente não identificam você
            diretamente, mas podem oferecer uma experiência na web mais
            personalizada.
          </Paragraph>
          <CookiesSwitch data-testid="cookies-switch" />
          <div className="cookies-wrapper button-content">
            <Button
              data-testid="reject-config-button"
              className="reject-config-button reject-button"
              appearance="primary"
              ghost={true}
              onClick={() => {
                createUserConsent(Consent_Action.REJECT, false);
                setAllCategories(false);
                setIsModalVisible(false);
                setIsBannerVisible(BANNER_HIDDEN_CLASS);
              }}
            >
              Rejeitar cookies
            </Button>
            <Button
              data-testid="save-config-button"
              className="save-config-button save-button"
              appearance="primary"
              ghost={true}
              onClick={() => {
                saveDataInTheLocalStorage(domain, categories);
                createUserConsent();
                setIsModalVisible(false);
                setIsBannerVisible(BANNER_HIDDEN_CLASS);
              }}
            >
              Salvar permissões
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
