import { Accordion, Switch } from '@sicredi/react';
import { AllCookiesSwitch } from '../AllCookiesSwitch';
import { useCategory } from '../../common/Context/Category.Context';
import '../ManagementModal/index.scss';

export const CookiesSwitch = () => {
  const { categories, setIndividualCategory } = useCategory();

  const formattedCategory = (categoryName: string) =>
    categoryName.charAt(0).toUpperCase() + categoryName.slice(1).toLowerCase();

  return (
    <span>
      <AllCookiesSwitch />
      {categories.map((item, index) => {
        return (
          <span key={item.uuid} className="cookies-wrapper">
            {item.name === 'Estritamente Necessários' && (
              <div className="strictly-necessary-content">
                <Accordion aria-label="Permissão de Cookies">
                  <Accordion.Item
                    label={'Estritamente necessários (sempre ativos)'}
                  >
                    {item.description}
                  </Accordion.Item>
                </Accordion>
              </div>
            )}
            {item.name !== 'Estritamente Necessários' && (
              <div className="cookies-switch-content">
                <Accordion
                  aria-label={item.name}
                  data-testid="accordion"
                  className="accordion-item"
                >
                  <Accordion.Item
                    label={formattedCategory(item.name)}
                    data-testid="accordion-item"
                  >
                    {item.description}
                  </Accordion.Item>
                </Accordion>
                <Switch
                  data-testid="cookies-switch"
                  name={`switch-${index}`}
                  aria-checked={item.able}
                  className="switch"
                  checked={item.able}
                  onChange={(event) => {
                    setIndividualCategory(item.uuid, event.target.checked);
                  }}
                />
              </div>
            )}
          </span>
        );
      })}
    </span>
  );
};
