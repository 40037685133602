export enum AllBrowsers {
  CHROME = 'CHROME',
  EDGE = 'EDGE',
  FIREFOX = 'FIREFOX',
  OPERA = 'OPERA',
  SAFARI = 'SAFARI',
  FIREFOX_ANDROID = 'FIREFOX FOR ANDROID',
  OPERA_ANDROID = 'OPERA ANDROID',
  SAFARI_IOS = 'MOBILE SAFARI',
  SAMSUNG = 'SAMSUNG INTERNET',
  WEBVIEW_ANDROID = 'WEBVIEW ANDROID',
}

export enum DesktopBrowsers_AgentData {
  CHROME = 'GOOGLE CHROME',
  EDGE = 'MICROSOFT EDGE',
  OPERA = 'OPERA',
}

export enum DesktopBrowsers_Agent {
  FIREFOX = 'FIREFOX',
  SAFARI = 'SAFARI',
}

export enum Consent_Action {
  SAVE = 'SAVE',
  REJECT = 'REJECT',
}
