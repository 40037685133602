import * as React from 'react';
import styles from '@sicredi/styles/_button.scss';
import cn from 'clsx';

export enum POSITION {
  AFTER = 'after',
  BEFORE = 'before',
}

export interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  children?: React.ReactNode;
  position?: POSITION;
}

const Icon: React.FC<Props> = ({
  position,
  children,
  className,
  ...props
}) => (
  <span
    className={cn(styles['icon'], className, {
      [styles['-before']]: position === POSITION.BEFORE,
      [styles['-after']]: position === POSITION.AFTER,
    })}
    {...props}
  >
    {children}
  </span>
);

export default Icon;
