import styles from '@sicredi/styles/_multistep.scss';
import cn from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { Icon } from '../icon';

import Item, { Props as ItemProps } from './Item';

export interface Schema extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
}

export interface AriaLabelProps extends Schema {
  'aria-label'?: string;
}

export interface AriaLabelledbybyProps extends Schema {
  'aria-labelledby': string;
}

export type Props = (AriaLabelProps | AriaLabelledbybyProps) & {
  currentStep?: number;
  initialStep?: number;
};

export interface CompoundedMultistep extends React.FC<Props> {
  Item: React.FC<ItemProps>;
}

type StepIconProps = {
  index: number;
  currentStep: number;
};

const StepIcon: React.FC<StepIconProps> = ({ index, currentStep }) =>
  index < currentStep
      ? <Icon name="feedback-success" size={16} className={styles['icon']} />
      : <span className={styles['icon']}></span>;

const Multistep: CompoundedMultistep = ({
  children,
  className,
  currentStep,
  initialStep,
  ...props
}) => {
  const contents = useMemo(() => {
    return React.Children.toArray(children)
      .filter(it => it && typeof it === 'object')
      .filter((it: React.ReactElement) => it.type
        && ((it.type as any).name === Item.name
          || (it.type as any).displayName === Item.name)) as React.ReactElement<ItemProps>[];
  }, [children]);
  const [internalCurrentStep, setInternalCurrentStep] = useState(initialStep || 0);
  const renderCurrentContentComponent = useMemo(() => {
    const current = contents.find((_, idx) => idx === internalCurrentStep);

    if (!current) {
      throw new Error(`Theres no step configured to number ${internalCurrentStep}`);
    }

    return current.props.render;
  }, [internalCurrentStep, contents]);

  useEffect(() => {
    typeof currentStep === 'number' && setInternalCurrentStep(currentStep);
  }, [currentStep]);

  const goTo = (step: number) => setInternalCurrentStep(step);
  const goToStepName = (name: string) => {
    const stepIndex = contents.findIndex(content => content.props.name === name);

    if (stepIndex < 0) {
      throw new Error(`Theres no step configured to name ${name}`);
    }

    setInternalCurrentStep(stepIndex);
  }
  const nextStep = () => setInternalCurrentStep(prev => prev + 1);
  const previousStep = () => setInternalCurrentStep(prev => prev - 1);

  return <>
    <ol data-items=""
      className={cn(styles['sicredi-multistep'], className)}
      aria-label={props['aria-labelledby'] ? undefined : props['aria-label']}
      {...props}
    >
      {
        contents.map((component, index) =>
          React.cloneElement(component, {
            className: internalCurrentStep > index ? styles['-checked'] : undefined,
            current: index === internalCurrentStep,
            icon: <StepIcon index={index} currentStep={internalCurrentStep} />,
          })
        )
      }
    </ol>
    {renderCurrentContentComponent && renderCurrentContentComponent({
      previousStep,
      nextStep,
      goTo,
      goToStepName,
      stepsLength: contents.length,
      currentStep: internalCurrentStep
    })}
  </>
};

Multistep.defaultProps = {
  'aria-label': 'Etapas',
};

Multistep.Item = Item;

export default Multistep;
