import React from 'react';
import { Helmet, HelmetProps } from 'react-helmet';

export type Props = HelmetProps & {
  lang?: string;
  description?: string;
};

const Meta: React.FC<Props> = ({
  lang = 'pt-BR',
  defaultTitle = 'Sicredi',
  titleTemplate = '%s - Sicredi',
  title,
  children,
  description,
  ...props
}) => {
  return (
    <Helmet
      titleTemplate={titleTemplate}
      defaultTitle={defaultTitle}
      {...props}
    >
      {lang && <html lang={lang} />}
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {children}
    </Helmet>
  );
};

export default Meta;
