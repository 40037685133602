import styles from '@sicredi/styles/_alert.scss';
import cn from 'clsx';
import React, { ReactNode } from 'react';

import { Spacing } from '../spacing';
import Button, { Props as SicrediButtonProps } from '../button/Button';

export interface Props {
  children?: React.ReactNode;
  icon?: ReactNode;
  appearance?: "info" | "warning";
  className?: string;
  button?: SicrediButtonProps;
}

const Alert: React.FC<Props> = ({
  icon,
  button,
  children,
  className,
  appearance,
  ...props
}) => {
  return (
    <div className={cn(styles['sicredi-alert'], styles[`-${appearance || "info"}`], className)} {...props}>
      {icon &&
        <>
          <div data-testid="sicredi-alert-icon" className={styles['icon']}>
            {icon}
          </div>
          <Spacing appearance="x-small" />
        </>
      }
      {children}

      {button && (
        <>
          <Spacing appearance="x-small" />
          <Button {...button} />
        </>
      )}
    </div>
  );
};

export default Alert;
