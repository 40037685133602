import * as React from 'react';
import cn from 'clsx';
import styles from '@sicredi/styles/_layout.scss';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

const Content: React.FC<Props> = ({
  children,
  className,
  ...props
}) => (
  <div className={cn(styles['sicredi-layout-content'], className)} {...props}>
    {children}
  </div>
);

export default Content;
