import styles from '@sicredi/styles/_radio.scss';
import cn from 'clsx';
import * as React from 'react';
import FieldSubscript from '../shared/field-subscript/FieldSubscript';
import { Spacing } from '../spacing';
import { hash } from '../utils';
import RadioIcon from './Icon';
import Single, { Props as SingleProps } from './Single';

export interface Data {
  label: React.ReactNode;
  info?: React.ReactNode;
  disabled?: boolean;
  description?: React.ReactNode;
  value: string | string[] | number;
}

export interface Schema extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode;
  name: string;
  data: Data[];
  value?: string | string[] | number;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  appearance?: 'card';
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  hintMessage?: React.ReactNode;
  errorMessage?: React.ReactNode;
  direction?: 'horizontal' | 'vertical';
}

export interface AriaLabelProps extends Schema {
  'aria-label': string;
}

export interface AriaLabelledbybyProps extends Schema {
  'aria-labelledby': string;
}

export type Props = AriaLabelProps | AriaLabelledbybyProps;

export interface CompoundedRadio
  extends React.ForwardRefExoticComponent<
    Props & React.RefAttributes<HTMLInputElement>
  > {
  Single: React.FC<SingleProps>;
}

const Radio = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      data,
      value,
      required,
      disabled,
      readOnly,
      onChange,
      className,
      hintMessage,
      errorMessage,
      appearance,
      direction,
      ...props
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = React.useState('');

    function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
      e.persist();

      setInternalValue(e.target.value);

      onChange && onChange(e);
    }

    return (
      <div className={cn(styles['sicredi-radio-wrapper'], className)}>
        <div
          role="group"
          data-group=""
          aria-label={props['aria-label'] || undefined}
          aria-labelledby={props['aria-labelledby'] || undefined}
          className={cn({
            [styles['-vertical']]: direction === 'vertical',
            [styles['-horizontal']]: direction === 'horizontal',
          })}
        >
          {data.map(radio => (
            <div
              data-radio=""
              key={`${hash(name + radio.value)}`}
              className={cn(styles['sicredi-radio'], {
                [styles['-invalid']]: !!errorMessage,
                [styles['-disabled']]: disabled ? disabled : radio.disabled,
                [styles['-readonly']]: readOnly,
                [styles['-card']]: appearance === 'card',
              })}
            >
              <input
                id={`${hash(name + radio.value)}-radio`}
                ref={ref}
                name={name}
                type="radio"
                role="radio"
                value={radio.value}
                checked={
                  String(value !== undefined ? value : internalValue) ===
                  String(radio.value)
                }
                disabled={disabled ? disabled : radio.disabled}
                tabIndex={
                  (disabled ? disabled : radio.disabled) || readOnly ? -1 : 0
                }
                onChange={handleChange}
                required={required}
                readOnly={readOnly}
                className={styles['input']}
                data-input=""
                data-testid={
                  props['data-testid'] ? props['data-testid'] : undefined
                }
                aria-invalid={errorMessage ? 'true' : 'false'}
                aria-checked={
                  String(value !== undefined ? value : internalValue) ===
                  String(radio.value)
                }
                aria-required={required ? 'true' : 'false'}
                aria-disabled={
                  (disabled ? disabled : radio.disabled) ? 'true' : 'false'
                }
                aria-labelledby={`${hash(name + radio.value)}-radio-label`}
                aria-errormessage={`${name}-radio-error`}
              />
              <label
                id={`${hash(name + radio.value)}-radio-label`}
                htmlFor={`${hash(name + radio.value)}-radio`}
                className={styles['label']}
                data-label=""
              >
                {appearance === 'card' ? (
                  <React.Fragment>
                    <div className={styles['header']}>
                      <div className={styles['title']}>
                        {radio.info && (
                          <div className={styles['info']}>{radio.info}</div>
                        )}
                        <div className={styles['heading']}>{radio.label}</div>
                      </div>
                      <RadioIcon
                        checked={
                          String(value || internalValue) === String(radio.value)
                        }
                      />
                    </div>
                    {radio.description && (
                      <div className={styles['description']}>
                        {radio.description}
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <RadioIcon
                      checked={
                        String(value || internalValue) === String(radio.value)
                      }
                    />

                    <span className={styles['label-text']}>
                      {appearance !== 'card' && radio.label}
                    </span>
                  </React.Fragment>
                )}
              </label>
            </div>
          ))}
        </div>
        {!disabled && (
          <>
            {(!!errorMessage || (!!hintMessage && !errorMessage)) && (
              <Spacing appearance="xx-small" />
            )}
            <FieldSubscript>
              <FieldSubscript.Group>
                <FieldSubscript.Message
                  id={`${name}-radio-error`}
                  show={!!errorMessage}
                  invalid={true}
                  aria-live="polite"
                  role="alert"
                  data-testid={
                    props['data-testid']
                      ? `${props['data-testid']}-error`
                      : undefined
                  }
                  data-message=""
                >
                  {errorMessage}
                </FieldSubscript.Message>
                <FieldSubscript.Message
                  id={`${name}-input-hint`}
                  show={!!hintMessage && !errorMessage}
                  data-testid={
                    props['data-testid']
                      ? `${props['data-testid']}-hint`
                      : undefined
                  }
                  data-message=""
                >
                  {hintMessage}
                </FieldSubscript.Message>
              </FieldSubscript.Group>
            </FieldSubscript>
          </>
        )}
      </div>
    );
  }
) as CompoundedRadio;

Radio.defaultProps = {
  direction: 'vertical',
};

Radio.Single = Single;

export default Radio;
