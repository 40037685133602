import { FC, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Button, Paragraph } from '@sicredi/react';
import './index.scss';
import '../../global.scss';
import { ManagementModal } from '../ManagementModal';
import { useCategory } from '../../common/Context/Category.Context';
import { useModal } from '../../common/Context/Modal.Context';
import { useDeleteCookiesDisabled } from '../../common/CustomHook/useDeleteCookiesDisabled/useDeleteCookiesDisabled';
import {
  BANNER_HIDDEN_CLASS,
  useBanner,
} from '../../common/Context/Banner.Context';
import { Consent_Action } from '../../common/Enums/Browsers.Enum';
import { getConsentService } from '../../services/getConsentService';
import { updatedDomain } from '../../common/BrowserDataManipulation/CreateDataInTheBrowser/createDataInTheBrowser';
import { getDomainService } from '../../services/getDomainService';
import { Domain } from '../../common/Interfaces/Domain.Interface';

export const Banner: FC = () => {
  const {
    createUserConsent,
    setAllCategories,
    domain_,
    domain,
    setDomain,
    setCategories,
  } = useCategory();
  const { isModalVisible, setIsModalVisible } = useModal();
  const { isBannerVisible, setIsBannerVisible } = useBanner();

  const showBanner = !isModalVisible && !Cookies.get('USERCUUID');

  const getDomainRequest = async () => {
    const domainData = await getDomainService(domain_);
    updatedDomain(domainData.updatedDomain);

    setDomain(domainData);
    setCategories(domainData.categoryDtos);

    return domainData;
  };

  const coverHidden = () => {
    if (isModalVisible) {
      return 'cover-hidden';
    }

    if (Cookies.get('USERCUUID')) {
      return 'cover-hidden';
    }

    if (isBannerVisible === 'banner-hidden') {
      return 'cover-hidden';
    }

    return '';
  };

  const recreateDataInLocalStorage = async (domain: Domain | null) => {
    if (!!Cookies.get('USERCUUID')) {
      const userUUID = Cookies.get('USERCUUID') as string;
      if (!localStorage.getItem('CookiesDomains')) {
        await getConsentService(domain, userUUID, domain_).catch(() => {
          Cookies.remove('USERCUUID');
        });
      }
    }
  };

  useEffect(() => {
    getDomainRequest().then((domain) => recreateDataInLocalStorage(domain));
  }, []);

  useDeleteCookiesDisabled();

  return (
    <>
      {domain && (
        <div id="banner-container" className="banner-container">
          {showBanner && (
            <section
              className={`banner ${isBannerVisible}`}
              data-testid="banner"
            >
              <span className="p-content" data-testid="p-content">
                <Paragraph
                  data-testid="cookies-paragraph"
                  className="cookies-paragraph"
                >
                  Utilizamos cookies neste site para melhorar sua experiência de
                  navegação de acordo com nossa{' '}
                  <a
                    className="cookies-a"
                    data-testid="cookies-a"
                    href={domain.cookieDtoTerm.linkTerm}
                  >
                    Política de Cookies.
                  </a>
                </Paragraph>
              </span>
              <div className="buttons-content">
                <Button
                  data-testid="management-cookies-button"
                  className="management-cookies-button"
                  appearance="primary"
                  ghost={true}
                  onClick={() => setIsModalVisible(true)}
                >
                  Gerenciar Permissões de Cookies
                </Button>
                <Button
                  data-testid="all-cookies-button"
                  className="all-cookies-button"
                  appearance="primary"
                  ghost={true}
                  onClick={() => {
                    setAllCategories(true);
                    createUserConsent(Consent_Action.SAVE, true);
                    setIsBannerVisible(BANNER_HIDDEN_CLASS);
                  }}
                >
                  Permitir Todos
                </Button>
              </div>
            </section>
          )}
          <ManagementModal />

          <div className={`cover ${coverHidden()}`} data-testid="cover"></div>
        </div>
      )}
    </>
  );
};
