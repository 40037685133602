import * as React from 'react';
import styles from '@sicredi/styles/_checkbox.scss';
import cn from 'clsx';

import { hash } from '../utils';
import FieldSubscript from '../shared/field-subscript/FieldSubscript';
import { Spacing } from '../spacing';

import Group, { Props as GroupProps } from './Group';
import CheckboxIcon from './Icon';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode;
  label?: React.ReactNode;
  description?: React.ReactNode;
  info?: React.ReactNode;
  hintMessage?: React.ReactNode;
  errorMessage?: React.ReactNode;
  appearance?: 'card';
}

export interface CompoundedCheckbox
  extends React.ForwardRefExoticComponent<
    Props & React.RefAttributes<HTMLInputElement>
  > {
  Group: React.FC<GroupProps>;
}

let i = 0;

function generateCheckboxName(): string {
  return hash((i = i + 1));
}

const Checkbox = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      label,
      description,
      info,
      checked,
      required,
      onChange,
      disabled,
      readOnly,
      className,
      hintMessage,
      errorMessage,
      appearance,
      ...props
    },
    ref
  ) => {
    const internalName = React.useMemo(() => name || generateCheckboxName(), [
      name,
    ]);
    const [internalCheck, setInternalCheck] = React.useState(false);

    function handleChange(
      e: React.ChangeEvent<HTMLInputElement>
    ): boolean | void {
      if (disabled || readOnly) {
        e.preventDefault();
        e.stopPropagation();

        return false;
      }

      e.persist();

      setInternalCheck(!internalCheck);

      onChange && onChange(e);
    }

    return (
      <div
        className={cn(styles['sicredi-checkbox'], className, {
          [styles['-invalid']]: !!errorMessage,
          [styles['-disabled']]: disabled,
          [styles['-readonly']]: readOnly,
          [styles['-card']]: appearance === 'card',
        })}
      >
        <input
          id={`${internalName}-checkbox`}
          ref={ref}
          name={internalName}
          type="checkbox"
          role="checkbox"
          checked={checked !== undefined ? checked : internalCheck}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          onChange={handleChange}
          tabIndex={disabled || readOnly ? -1 : 0}
          className={styles['input']}
          data-input=""
          aria-checked={checked !== undefined ? checked : internalCheck}
          aria-invalid={errorMessage ? 'true' : 'false'}
          aria-readonly={readOnly ? 'true' : 'false'}
          aria-disabled={disabled ? 'true' : 'false'}
          aria-required={required ? 'true' : 'false'}
          aria-labelledby={`${internalName}-checkbox-label`}
          aria-errormessage={`${internalName}-checkbox-error`}
          {...props}
        />
        <label
          id={`${internalName}-checkbox-label`}
          htmlFor={`${internalName}-checkbox`}
          className={styles['label']}
          data-label=""
        >
          {appearance === 'card' ? (
            <React.Fragment>
              <div className={styles['header']}>
                <div className={styles['title']}>
                  {info && <div className={styles['info']}>{info}</div>}
                  <div className={styles['heading']}>{label}</div>
                </div>
                <CheckboxIcon
                  checked={!!checked || internalCheck}
                />
              </div>
              {description && (
                <div className={styles['description']}>{description}</div>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <CheckboxIcon
                checked={!!checked || internalCheck}
              />
              {label && <div className={styles['label-text']}>{label}</div>}
            </React.Fragment>
          )}
        </label>
        {label && (
          <>
            <Spacing appearance="xx-small" />
            <FieldSubscript>
              <FieldSubscript.Group>
                <FieldSubscript.Message
                  id={`${internalName}-checkbox-error`}
                  show={!!errorMessage}
                  invalid={true}
                  aria-live="polite"
                  role="alert"
                  data-testid={
                    props['data-testid']
                      ? `${props['data-testid']}-error`
                      : undefined
                  }
                  data-message=""
                >
                  {errorMessage}
                </FieldSubscript.Message>
                <FieldSubscript.Message
                  id={`${internalName}-input-hint`}
                  show={!!hintMessage && !errorMessage}
                  data-testid={
                    props['data-testid']
                      ? `${props['data-testid']}-hint`
                      : undefined
                  }
                  data-message=""
                >
                  {hintMessage}
                </FieldSubscript.Message>
              </FieldSubscript.Group>
            </FieldSubscript>
          </>
        )}
      </div>
    );
  }
) as CompoundedCheckbox;

Checkbox.Group = Group;

export default Checkbox;
